import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import Operations2MinNew from "../../static/assets/investmentBack-min.webp";

const fadeInVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

const newsData = [
  {
    title:
      "TRAVERSE PIPELINE REACHES FINAL INVESTMENT DECISION TO TRANSPORT NATURAL GAS BETWEEN AGUA DULCE AND THE KATY AREA",
    description:
      "AUSTIN, Texas, April 3, 2025 /PRNewswire/ -- WhiteWater today announced that WhiteWater, MPLX LP (NYSE: MPLX), and Enbridge Inc. (NYSE: ENB), through the WPC joint venture (WPC), have partnered with an affiliate of Targa Resources Corp. (NYSE: TRGP) and have reached final investment decision to move forward with the construction of the Traverse Pipeline, having secured sufficient firm transportation agreements with investment grade shippers.",
    date: "Apr 03, 2025",
    fullDescription: (
      <>
        The bi-directional Traverse Pipeline is designed to transport up to 1.75
        billion cubic feet per day (Bcf/d) of natural gas through approximately
        160 miles of 36-inch pipeline along the Gulf Coast between Agua Dulce in
        South Texas and the Katy area. Supply for the Traverse Pipeline will be
        sourced from multiple connections, including, but not limited to, the
        Whistler, Blackcomb, and Matterhorn Express Pipelines. The Traverse
        Pipeline enhances optionality for shippers to access multiple premium
        markets.
        <br />
        <br />
        The Traverse Pipeline will be wholly owned by the Blackcomb Pipeline
        joint venture, which is owned 70.0% by WPC, 17.5% by Targa, and 12.5% by
        MPLX, which is incremental to MPLX's ownership interest in WPC.
        <br />
        <br />
        The Traverse Pipeline will be constructed and operated by WhiteWater and
        is expected to be in service in 2027, pending the receipt of customary
        regulatory and other approvals.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About the WPC Joint Venture
        </Typography>
        The WPC joint venture is owned by WhiteWater (50.6%), MPLX (30.4%), and
        Enbridge (19.0%). WPC owns long-haul natural gas pipelines and storage
        assets which transport natural gas from the Permian Basin to South Texas
        with direct connections to LNG export markets. The WPC joint venture
        owns the Whistler Pipeline, the Rio Bravo Pipeline, 70% of the Blackcomb
        Pipeline, 70% of the Traverse Pipeline, 70% of the ADCC Pipeline, and
        50% of the Waha Gas Storage facility. WhiteWater's stake in WPC is owned
        by I Squared Capital.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater is an Austin, Texas based infrastructure company and operator
        of multiple gas transmission assets, including the Whistler Pipeline and
        the Blackcomb Pipeline. WhiteWater is partnered with multiple private
        equity funds, including but not limited to I Squared Capital. For more
        information about WhiteWater, visit www.wwdev.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About I Squared Capital
        </Typography>
        I Squared is a leading independent global infrastructure investor
        managing over $40 billion in assets. We build investment platforms from
        the ground up—starting small and scaling big. Combining global insight
        with local expertise, we tackle complex challenges, back smarter
        businesses, and invest in sustainable infrastructure that delivers
        essential services to millions worldwide. Our team of 280+ is
        headquartered in Miami with offices in Abu Dhabi, London, Munich, New
        Delhi, São Paulo, Singapore, Sydney, and Taipei. We manage a diverse
        portfolio of 89 companies across 70+ countries, employing over 66,000
        people in sectors including utilities, energy, digital infrastructure,
        transport, environmental, and social infrastructure. Learn more at
        www.isquaredcapital.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. MPLX also owns crude oil and natural gas gathering systems
        and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Enbridge Inc.
        </Typography>
        At Enbridge, we safely connect millions of people to the energy they
        rely on every day, fueling quality of life through our North American
        natural gas, oil and renewable power networks and our growing European
        offshore wind portfolio. We're investing in modern energy delivery
        infrastructure to sustain access to secure, affordable energy and
        building on more than a century of operating conventional energy
        infrastructure and two decades of experience in renewable power. We're
        also advancing new technologies including hydrogen, renewable natural
        gas and carbon capture and storage. Headquartered in Calgary, Alberta,
        Enbridge's common shares trade under the symbol ENB on the Toronto (TSX)
        and New York (NYSE) stock exchanges. To learn more, visit us at
        enbridge.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Targa Resources Corp.
        </Typography>
        Targa Resources Corp. ("Targa") is a leading provider of midstream
        services and is one of the largest independent infrastructure companies
        in North America. Targa owns, operates, acquires and develops a
        diversified portfolio of complementary domestic infrastructure assets
        and its operations are critical to the efficient, safe and reliable
        delivery of energy across the United States and increasingly to the
        world. Targa's assets connect natural gas and NGLs to domestic and
        international markets with growing demand for cleaner fuels and
        feedstocks. Targa is primarily engaged in the business of: gathering,
        compressing, treating, processing, transporting, and purchasing and
        selling natural gas; transporting, storing, fractionating, treating, and
        purchasing and selling NGLs and NGL products, including services to LPG
        exporters; and gathering, storing, terminaling, and purchasing and
        selling crude oil.
        <br />
        <br />
        Targa is a FORTUNE 500 company and is included in the S&P 500.
        <br />
        <br />
        For more information, please visit the Company's website at
        www.targaresources.com.
        <br />
        <br />
        SOURCE WhiteWater
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "MPLX LP Announces Agreement to Acquire Remaining 55% Interest in BANGL, LLC, Advancing NGL Wellhead to Water Strategy",
    description:
      "FINDLAY, Ohio, Feb. 28, 2025 /PRNewswire/ -- MPLX LP (NYSE: MPLX) today announced it has signed a definitive agreement with affiliates of WhiteWater and Diamondback Energy to acquire the remaining 55% interest in BANGL, LLC for $715 million. Additionally, upon achievement of specific financial performance metrics, MPLX would make earnout payments up to a specified cap. The transaction is immediately accretive and is expected to generate mid-teen returns for the partnership.",
    date: "February 28, 2025",
    fullDescription: (
      <>
        "With full ownership of BANGL and its expansion opportunities, our
        growth platform is further improved for the long term as we connect
        growing NGL production from the Permian basin to our recently announced
        Gulf Coast fractionation complex," said Maryann Mannen, MPLX president
        and chief executive officer.
        <br />
        <br />
        The transaction is expected to close in July 2025 and is subject to
        customary closing conditions, including clearance under the
        Hart-Scott-Rodino Antitrust Improvements Act of 1976. Following closing,
        the BANGL Pipeline will be a wholly owned asset of MPLX and consolidated
        in MPLX's financial results.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About the BANGL Pipeline
        </Typography>
        The BANGL pipeline system transports up to 250 thousand barrels per day
        of natural gas liquids from the Permian basin of Texas to fractionation
        markets along the Gulf Coast. BANGL is being expanded to 300 thousand
        barrels per day, which is anticipated to come online in the second half
        of 2026. The BANGL pipeline system will enable liquids to reach MPLX's
        Gulf Coast fractionation complex, which is expected in service in 2028.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. The company also owns crude oil and natural gas gathering
        systems and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Investor Relations Contact: (419) 421-2071
        </Typography>
        Kristina Kazarian, Vice President Finance and Investor Relations
        <br />
        <br />
        Brian Worthington, Senior Director, Investor Relations
        <br />
        <br />
        Isaac Feeney, Director, Investor Relations
        <br />
        <br />
        Evan Heminger, Analyst, Investor Relations
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Media Contact: (419) 421-3577
        </Typography>
        Jamal Kheiry, Communications Manager
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater's investment in BANGL, LLC was held through an affiliate,
        WhiteWater BANGL Holdings, LLC, which is backed by Ridgemont Equity
        Partners, Trace Capital Management, and First Infrastructure Capital.
        WhiteWater was advised by Simpson Thacher & Bartlett, LLP as legal
        counsel and Barclays as financial advisor.
        <br />
        <br />
        This press release contains forward-looking statements regarding MPLX LP
        (MPLX). These forward-looking statements may relate to, among other
        things, MPLX's expectations, estimates and projections concerning its
        business and operations and financial and strategic priorities,
        including its NGL wellhead to water strategy and construction of its
        Gulf Coast fractionation complex, the acquisition of the remaining 55%
        interest in BANGL, LLC and the expansion of the BANGL pipeline system.
        You can identify forward-looking statements by words such as
        "anticipate," "believe," "commitment," "could," "design," "endeavor,"
        "estimate," "expect," "focus," "forecast," "goal," "guidance," "intend,"
        "may," "objective," "opportunity," "outlook," "plan," "policy,"
        "position," "potential," "predict," "priority," "progress," "project,"
        "prospective," "pursue," "seek," "should," "strategy," "strive,"
        "target," "trends," "will," "would" or other similar expressions that
        convey the uncertainty of future events or outcomes. MPLX cautions that
        these statements are based on management's current knowledge and
        expectations and are subject to certain risks and uncertainties, many of
        which are outside of the control of MPLX, that could cause actual
        results and events to differ materially from the statements made herein.
        Factors that could cause MPLX's actual results to differ materially from
        those implied in the forward-looking statements include but are not
        limited to: the adequacy of capital resources and liquidity, including
        the availability of capital resources to execute on its strategic
        priorities; the ability to access debt markets on commercially
        reasonable terms or at all; changes to the expected construction costs
        and in service dates of planned and ongoing projects and investments and
        the ability to obtain regulatory and other required approvals with
        respect thereto within the expected timeframes, if at all; the ability
        to obtain regulatory and other approvals and the satisfaction of the
        other conditions to the closing of the BANGL, LLC acquisition within the
        expected timeframe, if at all; and the other factors set forth under the
        heading "Risk Factors" and "Disclosures Regarding Forward-Looking
        Statements" in MPLX's and MPC's Annual Reports on Form 10-K for the year
        ended Dec. 31, 2024, and in other filings with the SEC.
        <br />
        <br />
        Any forward-looking statement speaks only as of the date of the
        applicable communication and we undertake no obligation to update any
        forward-looking statement except to the extent required by applicable
        law.
        <br />
        <br />
        Copies of MPLX's Annual Report on Form 10-K, Quarterly Reports on Form
        10-Q and other SEC filings are available on the SEC's website, MPLX's
        website at http://ir.mplx.com or by contacting MPLX's Investor Relations
        office. Copies of MPC's Annual Report on Form 10-K, Quarterly Reports on
        Form 10-Q and other SEC filings are available on the SEC's website,
        MPC's website at https://www.marathonpetroleum.com/Investors/ or by
        contacting MPC's Investor Relations office.
        <br />
        <br />
        SOURCE MPLX LP
        <br />
        <br />
      </>
    ),
  },
  {
    title: "Pelican Pipeline Reaches Final Investment Decision",
    description:
      "AUSTIN, Texas, Oct. 31, 2024 /PRNewswire/ -- WhiteWater has reached a final investment decision to move forward with the construction of the Pelican Pipeline after having secured sufficient firm transportation agreements with shippers.",
    fullDescription: (
      <>
        The Pelican Pipeline has been designed to transport up to 1.75 billion
        cubic feet per day (Bcf/d) of natural gas through approximately 170
        miles of 36-inch pipeline from Williams, Louisiana, to the Gillis Hub
        near Ragley, Louisiana. Supply for the Pelican Pipeline will be sourced
        from multiple upstream connections in the Haynesville Basin, including
        direct connections to processing facilities.
        <br />
        <br />
        The Pelican Pipeline is expected to be in service in the first half of
        2027, pending the receipt of customary regulatory and other approvals.
        <br />
        <br />
        WhiteWater, an Austin, Texas-based infrastructure company, has partnered
        with FIC, Stonepeak, and Trace Capital Management on the Pelican
        Pipeline.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About FIC
        </Typography>
        FIC is an investment firm with a focus on critical infrastructure assets
        across the power and power use value chains. FIC focuses on investment
        opportunities that generate long-term capital appreciation in
        generation, downstream, power and utilities, renewables, and
        data/telecommunications industries. We partner with management
        businesses to accelerate the development of strategic assets that serve
        society's growing energy needs and the associated decarbonization of
        industrial infrastructure.
        <br />
        For more information about FIC, please visit{" "}
        <a
          href="https://www.FICfund.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.FICfund.com
        </a>
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Stonepeak
        </Typography>
        Stonepeak is a leading alternative investment firm specializing in
        infrastructure and real assets with approximately $70 billion of assets
        under management. Through its investment in defensive, hard-asset
        businesses globally, Stonepeak aims to create value for its investors
        and portfolio companies, with a focus on downside protection and strong
        risk-adjusted returns. Stonepeak, as a sponsor of private equity and
        credit investment vehicles, provides capital, operational support, and
        committed partnership to grow investments in its target sectors, which
        include communications, energy and energy transition, transport and
        logistics, and real estate.
        <br />
        Stonepeak is headquartered in New York with offices in Houston, London,
        Hong Kong, Seoul, Singapore, Sydney, Tokyo, and Abu Dhabi. For more
        information, please visit{" "}
        <a
          href="https://www.stonepeak.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.stonepeak.com
        </a>
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Trace Capital Management
        </Typography>
        Trace Capital Management (Trace) is a proven and pragmatic energy
        investor focused on value and growth investments across the global
        energy landscape, with a particular focus on energy infrastructure,
        upstream oil and gas, and viable low/no carbon opportunities.
        <br />
        Based in Houston, Texas, Trace currently manages funds with invested and
        committed capital of more than $1.6 billion. Learn more at{" "}
        <a
          href="https://www.tracecapital.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.tracecapital.com
        </a>
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          SOURCE:
        </Typography>{" "}
        WhiteWater
      </>
    ),
    date: "October 31, 2024",
  },
  {
    title:
      "Blackcomb Pipeline Reaches Final Investment Decision to Transport Growing Natural Gas Production from the Permian Basin to the Gulf Coast Region",
    description:
      "AUSTIN, Texas, July 31, 2024 /PRNewswire/ -- WhiteWater, MPLX LP, and Enbridge Inc., through the WPC Joint Venture, have partnered with an affiliate of Targa Resources Corp. to reach final investment decision to move forward with the construction of the Blackcomb Pipeline after having secured sufficient firm transportation agreements with predominantly investment grade shippers, including, but not limited to, Devon Energy, Corp. (NYSE: DVN), Diamondback Energy, Inc. (NYSE: FANG), Marathon Petroleum Corp. (NYSE: MPC), and Targa Resources Corp.",
    date: "July 31, 2024",
    fullDescription: (
      <>
        WhiteWater today announced that WhiteWater, MPLX LP (NYSE: MPLX), and
        Enbridge Inc. (NYSE: ENB), through the WPC Joint Venture, the joint
        venture that owns the Whistler Pipeline, have partnered with an
        affiliate of Targa Resources Corp. (NYSE: TRGP) to reach a final
        investment decision to move forward with the construction of the
        Blackcomb Pipeline after securing firm transportation agreements with
        predominantly investment-grade shippers.
        <br />
        <br />
        The Blackcomb Pipeline is designed to transport up to 2.5 billion cubic
        feet per day (Bcf/d) of natural gas through approximately 365 miles of
        42-inch pipeline from the Permian Basin in West Texas to the Agua Dulce
        area in South Texas. Supply for the Blackcomb Pipeline will be sourced
        from multiple upstream connections in the Permian Basin, including
        processing facilities in the Midland Basin and the Agua Blanca Pipeline
        in the Delaware Basin, a joint venture between WhiteWater and MPLX.
        <br />
        <br />
        "We are excited to partner with Targa by leveraging Whistler’s expansive
        footprint in Waha and Agua Dulce to develop the Blackcomb Pipeline.
        Blackcomb will provide much-needed incremental natural gas takeaway
        capacity for Permian shippers," said Christopher Rundlof, CEO of
        WhiteWater.
        <br />
        <br />
        The Blackcomb Pipeline is a joint venture owned **70.0% by WPC, 17.5% by
        Targa, and 12.5% by MPLX,** which is incremental to MPLX’s ownership
        interest in WPC. The Blackcomb Pipeline will be constructed and operated
        by WhiteWater and is expected to be in service in the second half of
        2026, pending the receipt of customary regulatory and other approvals.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About the WPC Joint Venture
        </Typography>
        The WPC joint venture is owned by **WhiteWater (50.6%), MPLX (30.4%),
        and Enbridge (19.0%)**. WPC owns long-haul natural gas pipelines and
        storage assets which transport natural gas from the Permian Basin to
        South Texas with direct connections to LNG export markets. The WPC joint
        venture owns the Whistler Pipeline, the Rio Bravo Pipeline, **70% of the
        Blackcomb Pipeline, 70% of the ADCC Pipeline,** and **50% of the Waha
        Gas Storage facility**. WhiteWater’s stake in WPC is owned by I Squared
        Capital.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater is an Austin, Texas-based infrastructure company and operator
        of multiple gas transmission assets, including the Whistler Pipeline and
        the Blackcomb Pipeline. WhiteWater is partnered with multiple private
        equity funds, including but not limited to **I Squared Capital**. For
        more information about WhiteWater, visit{" "}
        <a
          href="https://www.wwdev.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.wwdev.com
        </a>
        .
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About I Squared Capital
        </Typography>
        I Squared Capital is a leading independent global infrastructure
        investment manager with over **$40 billion in assets under management**.
        The firm develops investment platforms in infrastructure projects,
        leveraging global insights and deep local intelligence. I Squared
        Capital operates **85 companies in 81 countries** with over **66,000
        employees** in utilities, energy, digital infrastructure, transport,
        environmental infrastructure, and social infrastructure. Learn more at{" "}
        <a
          href="https://www.isquaredcapital.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.isquaredcapital.com
        </a>
        .
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. MPLX also owns crude oil and natural gas gathering systems
        and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basin. Learn more at{" "}
        <a
          href="http://www.mplx.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.mplx.com/
        </a>
        .
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Enbridge Inc.
        </Typography>
        At Enbridge, we safely connect millions of people to the energy they
        rely on every day, fueling quality of life through our North American
        natural gas, oil and renewable power networks and our growing European
        offshore wind portfolio. We're investing in modern energy delivery
        infrastructure to sustain access to secure, affordable energy and
        building on more than a century of operating conventional energy
        infrastructure and two decades of experience in renewable power to
        advance new technologies including hydrogen, renewable natural gas and
        carbon capture and storage. Headquartered in Calgary, Alberta,
        Enbridge's common shares trade under the symbol ENB on the Toronto (TSX)
        and New York (NYSE) stock exchanges. Learn more at{" "}
        <a
          href="https://www.enbridge.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.enbridge.com
        </a>
        .
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Targa Resources Corp.
        </Typography>
        Targa Resources Corp. ("Targa") is a leading provider of midstream
        services and is one of the largest independent midstream infrastructure
        companies in North America. Targa owns, operates, acquires and develops
        a diversified portfolio of complementary domestic midstream
        infrastructure assets and its operations are critical to the efficient,
        safe and reliable delivery of energy across the United States and
        increasingly to the world. Targa's assets connect natural gas and NGLs
        to domestic and international markets with growing demand for cleaner
        fuels and feedstocks. Targa is primarily engaged in the business of:
        gathering, compressing, treating, processing, transporting, and
        purchasing and selling natural gas; transporting, storing,
        fractionating, treating, and purchasing and selling NGLs and NGL
        products, including services to LPG exporters; and gathering, storing,
        terminaling, and purchasing and selling crude oil.. Learn more at{" "}
        <a
          href="http://www.targaresources.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http:/www.targaresources.com/
        </a>
        <br />
        <br />
        Targa is a FORTUNE 500 company and is included in the S&P 500. .
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "Whistler Pipeline LLC Prices Offering of $1.85 Billion of Senior Unsecured Notes",
    description:
      "AUSTIN, Texas, July 25, 2024 /PRNewswire/ -- Whistler Pipeline LLC ('Whistler') announced today that it priced an offering (the 'Notes Offering') of $1.85 billion aggregate principal amount of senior unsecured notes, consisting of $400 million aggregate principal amount of 5.400% Senior Notes due 2029, $500 million aggregate principal amount of 5.700% Senior Notes due 2031, $825 million aggregate principal amount of 5.950% Senior Notes due 2034 and $125 million aggregate principal amount of 6.350% Senior Notes due 2044 (collectively, the 'Notes').",
    date: "July 25, 2024",
    fullDescription: (
      <>
        The Notes Offering is expected to close August 5, 2024, subject to
        satisfaction of customary closing conditions.
        <br />
        <br />
        Whistler intends to use the net proceeds from the Notes Offering to
        redeem or repay substantially all of its outstanding indebtedness,
        including the outstanding aggregate principal amount of its 5.21% Series
        A Senior Notes due 2030 (the "Notes-A"), the outstanding aggregate
        principal amount of its 7.00% Series B Senior Notes due 2031 (the
        "Notes-B") and the outstanding indebtedness under Whistler's existing
        term loan facilities, with any remaining amount to be used for general
        corporate purposes, including the funding of future growth capital.
        2027, pending the receipt of customary regulatory and other approvals.
        <br />
        <br />
        Pursuant to the terms of the note purchase agreement governing the
        Notes-A and Notes-B (the "Note Purchase Agreement"), Whistler intends to
        issue revocable notices of redemption to redeem all of the outstanding
        Notes-A and Notes-B, which redemption will be conditional upon the
        successful completion of the Notes Offering. Pipeline.
        <br />
        <br />
        The securities to be offered have not been registered under the
        Securities Act of 1933, as amended (the "Securities Act"), or any state
        securities laws. Unless so registered, the securities may not be offered
        or sold in the United States except pursuant to an exemption from, or in
        a transaction not subject to, the registration requirements of the
        Securities Act and applicable state securities laws. Whistler plans to
        offer and sell the securities only to qualified institutional buyers
        pursuant to Rule 144A under the Securities Act and to non-U.S. persons
        in transactions outside the United States pursuant to Regulation S under
        the Securities Act.
        <br />
        <br />
        This press release does not constitute an offer to sell or the
        solicitation of an offer to buy any security, nor shall there be any
        sale of the New Notes or any other security of Whistler, in any
        jurisdiction in which such offer, solicitation or sale would be unlawful
        prior to the registration or qualification under the securities laws of
        any such jurisdiction. This press release does not constitute a notice
        of redemption under the optional redemption provisions of the Note
        Purchase Agreement governing the Notes-A and Notes-B.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Cautionary Note Concerning Forward-Looking Statements
        </Typography>
        Disclosures in this press release contain forward-looking statements.
        All statements, other than statements of historical fact, included in
        this press release are forward-looking statements. Words such as
        "expects," "anticipates," "intends," "plans," "believes," "seeks,"
        "estimates," variations of such words and similar expressions are
        intended to identify such forward looking statements, although not all
        forward-looking statements contain such identifying words. Similarly,
        statements that describe our objectives, plans or goals are or may be
        forward looking statements. Without limiting the generality of the
        foregoing, forward-looking statements contained in this press release
        specifically include statements regarding the Notes Offering and the use
        of proceeds therefrom. Such statements are not guarantees of future
        performance and involve various risks, uncertainties and assumptions,
        which are difficult to predict and many of which are outside our
        control. Therefore, actual results, performance and achievements may
        differ materially from what is expressed or forecasted in such forward
        looking statements. Prospective investors should not place undue
        reliance on forward looking statements, which speak only as of the date
        of this press release. Whistler does not intend to correct or update any
        forward-looking statement, whether as a result of new information,
        future events or otherwise, except as required by law.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Whistler Pipeline
        </Typography>
        Whistler owns the Whistler Pipeline (the "Pipeline"), which is an
        approximately 450-mile, 42-inch intrastate natural gas pipeline with
        approximately 2.5 billion cubic feet per day ("Bcf/d") of capacity that
        transports natural gas from the Permian Basin to a terminus near Agua
        Dulce, Texas, providing direct access to South Texas markets and
        consumers. The Pipeline also has an approximately 85-mile, 36-inch
        diameter lateral to the Midland Basin. Whistler also owns a 50%
        membership interest in Waha Gas Storage, LLC, and an indirect 70%
        interest in the ADCC Pipeline, LLC, an approximately 40-mile, 42-inch
        intrastate pipeline that is designed to transport up to 1.7 Bcf/d,
        expandable to 2.5 Bcf/d of natural gas from the terminus of the Pipeline
        in Agua Dulce, Texas to Cheniere Corpus Christi Holdings, LLC's Corpus
        Christi liquefaction facility. Whistler is a joint venture with one
        class of limited liability company interests owned by WPC Parent, LLC
        ("WPC Parent"), and WhiteWater Whistler Holdings, LLC, MPLX LP, and
        Enbridge, Inc. own 50.6%, 30.4%, and 19.0%, respectively, of the
        outstanding equity interests in WPC Parent.
        <br />
        <br />
        SOURCE WhiteWater
      </>
    ),
  },
  {
    title: "ADCC Pipeline Begins Commercial Service",
    description:
      "AUSTIN, Texas, July 12, 2024 /PRNewswire/ -- The ADCC Pipeline entered commercial service on July 1, 2024, and is capable of providing approximately 1.7 Billion cubic feet per day (Bcf/d) of natural gas transportation capacity to the Cheniere Corpus Christi Liquefaction facility from markets on Whistler Pipeline's Agua Dulce Header in South Texas. The receipt points in Agua Dulce provide Cheniere direct access to Permian and Eagle Ford volumes in addition to volumes sourced along the Gulf Coast.",
    date: "July 12, 2024",
    fullDescription: (
      <>
        The ADCC Pipeline further enhances the natural gas infrastructure in
        Texas and creates an additional demand point for Permian gas. This
        infrastructure is expected to help reduce flared volumes in the Permian
        Basin and further support U.S. LNG exports to global markets.
        <br />
        <br />
        The ADCC Pipeline is a joint venture owned 70% by Whistler Pipeline LLC,
        which is a joint venture between WhiteWater (50.6%), MPLX LP (30.4%)
        (NYSE: MPLX), and Enbridge (19.0%) (NYSE: ENB), and 30% by a
        wholly-owned subsidiary of Cheniere Energy, Inc. (NYSE: LNG).
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT THE ADCC PIPELINE
        </Typography>
        The ADCC Pipeline is an approximately 40-mile, 42-inch intrastate
        pipeline that transports natural gas from Whistler Pipeline's Agua Dulce
        Header to Cheniere's Corpus Christi Liquefaction facility.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT THE WHISTLER PIPELINE
        </Typography>
        The Whistler Pipeline is an approximately 450-mile, 42-inch intrastate
        pipeline that transports natural gas from the Waha Header in the Permian
        Basin to Agua Dulce, Texas, providing direct access to South Texas and
        export markets. An approximately 50-mile 36-inch lateral provides
        connectivity to the Midland Basin.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater is an Austin, Texas based infrastructure company, and
        operator of the Whistler Pipeline. WhiteWater is partnered with multiple
        private equity funds including but not limited to I Squared Capital. For
        more information about WhiteWater, visit www.wwdev.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. MPLX also owns crude oil and natural gas gathering systems
        and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLXAbout Enbridge Inc.
        </Typography>
        At Enbridge, we safely connect millions of people to the energy they
        rely on every day, fueling quality of life through our North American
        natural gas, oil and renewable power networks and our growing European
        offshore wind portfolio. We're investing in modern energy delivery
        infrastructure to sustain access to secure, affordable energy and
        building on more than a century of operating conventional energy
        infrastructure and two decades of experience in renewable power to
        advance new technologies including hydrogen, renewable natural gas and
        carbon capture and storage. Headquartered in Calgary, Alberta,
        Enbridge's common shares trade under the symbol ENB on the Toronto (TSX)
        and New York (NYSE) stock exchanges. To learn more, visit us at
        enbridge.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Cheniere
        </Typography>
        Cheniere Energy, Inc. is the leading producer and exporter of LNG in the
        United States, reliably providing a clean, secure, and affordable
        solution to the growing global need for natural gas. Cheniere is a
        full-service LNG provider, with capabilities that include gas
        procurement and transportation, liquefaction, vessel chartering, and LNG
        delivery. Cheniere has one of the largest liquefaction platforms in the
        world, consisting of the Sabine Pass and Corpus Christi liquefaction
        facilities on the U.S. Gulf Coast, with total production capacity of
        approximately 45 mtpa of LNG in operation and an additional 10+ mtpa of
        expected production capacity under construction. Cheniere is also
        pursuing liquefaction expansion opportunities and other projects along
        the LNG value chain. Cheniere is headquartered in Houston, Texas, and
        has additional offices in London, Singapore, Beijing, Tokyo, and
        Washington, D.C.
        <br />
        <br />
        For additional information, please refer to the Cheniere website at
        www.cheniere.com and Quarterly Report on Form 10-Q for the quarter ended
        March 31, 2024, filed with the Securities and Exchange Commission.
        <br />
        <br />
        SOURCE WhiteWater
      </>
    ),
  },
  {
    title:
      "WHISTLER PIPELINE AND ENBRIDGE AGREE TO STRATEGIC COMBINATION OF WHISTLER AND RIO BRAVO NATURAL GAS ASSETS",
    description:
      "AUSTIN, Texas, March 26, 2024 /PRNewswire/ -- WhiteWater, I Squared, MPLX LP (NYSE: MPLX), and Enbridge Inc. (TSX: ENB) (NYSE: ENB) jointly announce they have entered into a definitive agreement to strategically combine the Whistler Pipeline and Rio Bravo Pipeline project in a newly formed joint venture. This combination creates an integrated natural gas pipeline system in the Permian Basin and allows for more flexibility in transporting natural gas to various markets in North America and beyond. The combined project is expected to strengthen WhiteWater’s position as a leading provider of pipeline transportation services for natural gas in the region.",
    date: "March 26, 2024",
    fullDescription: (
      <>
        AUSTIN, Texas, March 26, 2024 /PRNewswire/ -- WhiteWater, I Squared,
        MPLX LP (NYSE: MPLX), and Enbridge Inc. (TSX: ENB) (NYSE: ENB) jointly
        announce they have entered into a definitive agreement to strategically
        combine the Whistler Pipeline and Rio Bravo Pipeline project in a newly
        formed joint venture.
        <br />
        <br />
        Enbridge will contribute its wholly-owned Rio Bravo Pipeline project and
        cash in exchange for an ownership stake in the newly formed joint
        venture. Following the closing of the transaction, the ownership in the
        joint venture will be WhiteWater/I Squared (50.6%), MPLX (30.4%), and
        Enbridge (19.0%). Enbridge will retain a 25% economic interest in the
        Rio Bravo Pipeline project as part of its investment (subject to certain
        redemption rights of the joint venture partners). WhiteWater will
        continue to operate the joint venture's assets, including the Rio Bravo
        Pipeline project.
        <br />
        <br />
        The combined platform will provide significant benefits to the joint
        venture's customers by connecting Permian supply to incremental LNG
        export markets via Rio Bravo's connectivity with NextDecade's Rio Grande
        LNG facility. Additionally, the creation of this platform is anticipated
        to support the development of incremental pipeline projects connecting
        Permian supply to export markets along the Gulf Coast.
        <br />
        <br />
        The transaction is expected to close in the second quarter of 2024 after
        receipt of required regulatory approvals and satisfaction of other
        customary closing conditions.
        <br />
        <br />
        "This strategic transaction is a win for WhiteWater/I Squared and MPLX.
        It extends the average contract tenor, brings us closer to an important
        strategic partner in Enbridge and positions us well for future
        development opportunities supporting Gulf Coast LNG. WhiteWater is
        excited to add Enbridge as a partner and NextDecade's Rio Grande LNG as
        a customer. Together we will continue to grow our Texas pipeline
        network," said Christer Rundlof, Chief Executive Officer of WhiteWater.
        <br />
        <br />
        "This transaction is a continuation of MPLX's wellhead-to-water growth
        strategy, enhancing our natural gas value chain in the Permian basin and
        South Texas. The strategic combination of these assets position the
        Whistler Pipeline system for future capacity expansions to meet
        increasing demand for natural gas supply to Gulf Coast LNG facilities,"
        said Dave Heppner, Senior Vice President of MPLX.
        <br />
        <br />
        "We are excited to join WhiteWater/I Squared and MPLX in a joint venture
        to connect sustainable Permian natural gas production to export markets
        as part of our USGC strategy. Acquiring a meaningful equity interest in
        an integrated natural gas pipeline and storage network that is directly
        connected to our existing infrastructure provides customers, like
        NextDecade, with valuable last mile connectivity and unlocks future
        growth opportunities," said Cynthia Hansen, EVP and President, Gas
        Transmission and Midstream of Enbridge.
        <br />
        <br />
        "The combination of Whistler Pipeline and Rio Bravo Pipeline assets
        confirms the importance of our Rio Grande LNG project as a major market
        for associated gas from the Permian Basin," said Matt Schatzman,
        NextDecade Chairman and Chief Executive Officer. "We are excited to have
        WhiteWater, I Squared, MPLX and Enbridge as partners in the delivery of
        the Rio Bravo Pipeline for the Rio Grande LNG project and we look
        forward to growing our relationship with their new joint venture."
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About the Whistler Pipeline
        </Typography>
        The Whistler Pipeline has approximately 2.5 billion cubic feet per day
        of capacity and the system is comprised of approximately 450 miles of
        42-inch diameter pipeline that transports natural gas from the Permian
        Basin to Agua Dulce, Texas, with direct connections to Corpus Christi
        and the gulf coast. The pipeline also has an approximately 85-mile,
        36-inch diameter lateral to the Midland Basin.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater is an Austin, Texas based infrastructure company, and
        operator of the Whistler Pipeline. WhiteWater is partnered with multiple
        private equity funds including but not limited to I Squared Capital. For
        more information about WhiteWater, visit www.wwdev.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        I Squared Capital is a leading independent global infrastructure
        investment manager with over $38 billion in assets under management
        focused on investing in North America, Europe, Asia, and Latin America.
        Headquartered in Miami, the firm has more than 260 professionals across
        its offices in London, Munich, New Delhi, São Paulo, Singapore, Sydney
        and Taipei. I Squared Capital has invested in a diverse portfolio of 82
        companies with over 66,000 employees in 71 countries across the
        utilities, energy, digital infrastructure, transport, environmental
        infrastructure, and social infrastructure sectors providing essential
        services to millions of people around the world. You can find out more
        by visiting: www.isquaredcapital.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. MPLX also owns crude oil and natural gas gathering systems
        and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Enbridge Inc.
        </Typography>
        At Enbridge, we safely connect millions of people to the energy they
        rely on every day, fueling quality of life through our North American
        natural gas, oil, and renewable power networks and our growing European
        offshore wind portfolio. We are investing in modern energy delivery
        infrastructure to sustain access to secure, affordable energy and
        building on more than a century of operating conventional energy
        infrastructure and two decades of experience in renewable power. We are
        advancing new technologies, including hydrogen, renewable natural gas,
        carbon capture, and storage, and are committed to achieving net zero
        greenhouse gas emissions by 2050. Headquartered in Calgary, Alberta,
        Enbridge's common shares trade under the symbol ENB on the Toronto (TSX)
        and New York (NYSE) stock exchanges. To learn more, visit us at
        enbridge.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          MPLX Forward-Looking Statement
        </Typography>
        This press release contains forward-looking statements regarding MPLX.
        These forward-looking statements may relate to, among other things,
        MPLX's expectations, estimates and projections concerning its business
        and operations, including with respect to MPLX's investment in the joint
        venture and the strategic combination announced herein (the
        "Transaction"), including anticipated benefits and expected closing date
        of the Transaction. You can identify forward-looking statements by words
        such as "anticipate," "believe," "commitment," "continue," "could,"
        "design," "estimate," "expect," "forecast," "future," "goal,"
        "guidance," "intend," "may," "objective," "opportunity," "outlook,"
        "plan," "policy," "position," "potential," "predict," "priority,"
        "project," "prospective," "pursue," "seek," "should," "strategy,"
        "target," "will," "would" or other similar expressions that convey the
        uncertainty of future events or outcomes. MPLX cautions that these
        statements are based on management's current knowledge and expectations
        and are subject to certain risks and uncertainties, many of which are
        outside of the control of MPLX, that could cause actual results and
        events to differ materially from the statements made herein. Factors
        that could cause MPLX's actual results to differ materially from those
        implied in the forward-looking statements include but are not limited
        to: the timing and impact of the Transaction; the timing and extent of
        changes in commodity prices and demand for natural gas, crude oil,
        refined products, feedstocks or other hydrocarbon-based products or
        renewables; changes in the timing and construction costs and in service
        dates of planned and ongoing projects and investments, including with
        respect to the Transaction; the ability to obtain regulatory and other
        approvals with respect to the Transaction or related planned projects;
        industrial incidents or other unscheduled shutdowns; other risk factors
        inherent to MPLX's industry; the impact of adverse market conditions or
        other similar risks to those identified herein affecting MPLX; and the
        factors set forth under the heading "Risk Factors" in MPLX's and Annual
        Report on Form 10-K for the year ended Dec. 31, 2023, and in other
        filings with the SEC.
        <br />
        <br />
        All forward-looking statements, whether written or oral, attributable to
        MPLX or persons acting on MPLX's behalf, are expressly qualified in
        their entirety by these cautionary statements. Any forward-looking
        statement speaks only as of the date of the applicable communication and
        we undertake no obligation to update any forward-looking statement
        except to the extent required by applicable law.
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Enbridge Forward-Looking Statement
        </Typography>
        Forward-looking information, or forward-looking statements, have been
        included in this news release to provide information about Enbridge and
        its subsidiaries and affiliates, including management's assessment of
        Enbridge and its subsidiaries' future plans and operations. This
        information may not be appropriate for other purposes. Forward-looking
        statements are typically identified by words such as ''anticipate'',
        ''expect'', ''project'', ''estimate'', ''forecast'', ''plan'',
        ''intend'', ''target'', ''believe'', "likely" and similar words
        suggesting future outcomes or statements regarding an outlook.
        Forward-looking information or statements included or incorporated by
        reference in this document include, but are not limited to, statements
        with respect to Enbridge's investment in the joint venture and related
        matters (the "Transaction"), including anticipated benefits and expected
        closing date of the Transaction.
        <br />
        <br />
      </>
    ),
  },
  {
    title: "I Squared Capital Acquires Majority Interest in Whistler Pipeline",
    description:
      "MIAMI--(BUSINESS WIRE)--I Squared Capital, through its ISQ Global Infrastructure Fund III, has acquired a controlling interest in the Whistler Pipeline, a leading natural gas infrastructure asset, from First Infrastructure Capital, Ridgemont Equity Partners, affiliates of West Texas Gas Inc., affiliates of Stonepeak Partners LP and the WhiteWater management team.",
    date: "February 16, 2023",
    fullDescription: (
      <>
        The Whistler Pipeline is a leading core energy infrastructure system
        connecting the Permian Basin’s growing natural gas supply to LNG,
        Mexico, and Gulf Coast demand. Whistler will have direct connections
        into LNG facilities in the Corpus Christi area. Nearly all current
        capacity is contracted under long-term, fixed-fee minimum volume
        commitments, primarily with investment grade counterparties.
        <br />
        <br />
        I Squared Capital sees increasing long-term demand for natural gas
        across the U.S. Gulf Coast due to the growth in LNG liquefaction
        capacity being constructed in the region, as well as growing demand from
        Mexico. The Whistler pipeline offers the rare combination of strong free
        cash flow, high-quality contracts, and operating rights on highly
        strategic natural gas infrastructure.
        <br />
        <br />
        The management team, who retain a significant portion of equity in the
        business, has an established reputation for growing contracted cash
        flows through developing and operating greenfield projects and has
        identified several initiatives to accelerate further growth of the
        platform. Christer Rundlof, CEO of WhiteWater, said, “I’d like to thank
        our JV partners, shippers and current investors for their support.
        Whistler has been a tremendous success providing a much-needed
        connection between the Permian Basin and gas demand. We are very excited
        about the partnership with I Squared and look forward to continuing to
        serve our customers with Whistler and future, related projects.”
        <br />
        <br />
        Given the long-term demand for LNG infrastructure in this strategic
        area, I Squared Capital is evaluating additional investments to promote
        the energy transition and support the security of the global energy
        supply at a critical time.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About the Whistler Pipeline
        </Typography>
        Completed in June 2021, the Whistler Pipeline has approximately 2.0
        billion cubic feet per day of current capacity that will be expanded to
        2.5 billion cubic feet per day by late 2023. The system is comprised of
        approximately 450 miles of 42-inch diameter pipeline that transports
        natural gas from the Waha area in the Permian Basin to Agua Dulce, Texas
        with connections on to Corpus Christi and the coast. The pipeline also
        has an approximately 85-mile, 36-inch diameter lateral to the Midland
        Basin.
        <br />
        <br />
        I Squared Capital was advised by Kirkland & Ellis LLP as legal counsel
        and by TPH&Co., the energy business of Perella Weinberg Partners, as
        lead financial advisor, with Goldman Sachs as financial advisor. First
        Infrastructure Capital, Ridgemont Equity Partners and WhiteWater were
        advised by Simpson Thacher & Bartlett, LLP as legal counsel and Barclays
        as financial advisor. First Infrastructure Capital and Ridgemont Equity
        Partners were also advised by Milbank LLP and Troutman Pepper,
        respectively, as legal counsel. Stonepeak was advised by Sidley Austin
        LLP as legal counsel and RBC Capital Markets as financial advisor.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About I Squared Capital
        </Typography>
        I Squared Capital is an independent global infrastructure investment
        manager with over $36 billion in assets under management focusing on
        energy, utilities, digital infrastructure, environmental infrastructure,
        transport and social infrastructure in North America, Europe, Latin
        America, and Asia. The firm has offices in Miami, Hong Kong, London, New
        Delhi, Singapore, Taipei, and Sydney.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
        Andreas Moon
        <br />
        Partner and Head of Investor Relations
        <br />
        I Squared Capital
        <br />
        andreas.moon@isquaredcapital.com
        <br />
        <br />
      </>
    ),
  },
  {
    title: "Matterhorn Express Pipeline Reaches Final Investment Decision",
    description:
      "AUSTIN, Texas --- WhiteWater, EnLink Midstream, LLC (NYSE: ENLC), Devon Energy Corp. (NYSE: DVN), and MPLX LP (NYSE: MPLX) have reached a final investment decision to move forward with the construction of the Matterhorn Express Pipeline after having secured sufficient firm transportation agreements with shippers.",
    date: "May 19, 2022",
    fullDescription: (
      <>
        The Matterhorn Express Pipeline has been designed to transport up to 2.5
        billion cubic feet per day (Bcf/d) of natural gas through approximately
        490 miles of 42-inch pipeline from Waha, Texas, to the Katy area near
        Houston, Texas. Supply for the Matterhorn Express Pipeline will be
        sourced from multiple upstream connections in the Permian Basin,
        including direct connections to processing facilities in the Midland
        Basin through an approximately 75-mile lateral, as well as a direct
        connection to the 3.2 Bcf/d Agua Blanca Pipeline, a joint venture
        between WhiteWater and MPLX.
        <br />
        <br />
        “WhiteWater is excited to partner with EnLink, Devon, and MPLX to
        develop incremental gas transportation out of the Permian Basin as
        production continues to grow in West Texas,” said Christer Rundlof, CEO
        of WhiteWater. “Matterhorn will provide premium market access with
        superior flexibility for Permian Basin shippers while playing a critical
        role in minimizing flared volumes.”
        <br />
        <br />
        The Matterhorn Express Pipeline is expected to be in service in the
        third quarter of 2024, pending the receipt of customary regulatory and
        other approvals.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About the Whistler Pipeline
        </Typography>
        WhiteWater, an Austin, Texas based infrastructure company, has partnered
        with Ridgemont Equity Partners and First Infrastructure Capital on the
        Matterhorn Express Pipeline. For more information about WhiteWater,
        visit www.wwdev.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About EnLink Midstream
        </Typography>
        EnLink Midstream reliably operates a differentiated midstream platform
        that is built for long-term, sustainable value creation. EnLink's
        best-in-class services span the midstream value chain, providing natural
        gas, crude oil, condensate, and NGL capabilities, and carbon capture,
        transportation, and sequestration services. EnLink’s purposely built,
        integrated asset platforms are in premier production basins and core
        demand centers, including the Permian Basin, Oklahoma, North Texas, and
        the Gulf Coast. EnLink's strong financial foundation and commitment to
        execution excellence drive competitive returns and value for its
        employees, customers, and investors. Headquartered in Dallas, EnLink is
        publicly traded through EnLink Midstream, LLC (NYSE: ENLC). Visit
        www.EnLink.com to learn how EnLink connects energy to life.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Devon Energy
        </Typography>
        Devon Energy is a leading oil and gas producer in the U.S. with a
        premier multi-basin portfolio headlined by a world-class acreage
        position in the Delaware Basin. Devon’s disciplined cash-return business
        model is designed to achieve strong returns, generate free cash flow and
        return capital to shareholders, while focusing on safe and sustainable
        operations. For more information, please visit www.devonenergy.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. MPLX also owns crude oil and natural gas gathering systems
        and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About First Infrastructure Capital
        </Typography>
        First Infrastructure Capital Advisors, LLC is a Houston-based investment
        firm specializing in greenfield projects and companies operating in the
        midstream, downstream, electric power, telecommunications, and renewable
        energy industries. First Infrastructure Capital Advisors, LLC is an
        SEC-registered investment adviser, which manages funds affiliated with
        First Infrastructure Capital, L.P. For more information about First
        Infrastructure Capital, visit www.firstinfracap.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Ridgemont Equity Partners
        </Typography>
        Ridgemont Equity Partners is a Charlotte-based middle market buyout and
        growth equity investor. Since 1993, the principals of Ridgemont have
        invested over $6.0 billion. The firm focuses on equity investments up to
        $250 million and utilizes a proven, industry-focused investment approach
        and repeatable value creation strategies. For more information about
        Ridgemont, visit www.ridgemontep.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
        WhiteWater Investor Relations: www.wwdev.com
        <br />
        <br />
        EnLink Midstream Partners Investor Relations: Brian Brungardt (214)
        721-9353
        <br />
        <br />
        Devon Energy Investor Relations: Scott Coody (405) 552-4735
        <br />
        <br />
        MPLX LP Investor Relations: Kristina Kazarian (419) 421-2071
        <br />
        <br />
        Some of the above statements constitute forward-looking statements.
        Forward-looking statements are not guarantees of future performance and
        are subject to risks, uncertainties and other factors, some of which are
        beyond the control of the companies named herein and are difficult to
        predict. Factors that could impact the opportunities described above
        include but are not limited to general domestic and international
        economic and political conditions and the factors described in the
        filings with the Securities and Exchange Commission of the companies
        named herein. Any forward-looking statement speaks only as of the date
        of the applicable communication and the companies named herein undertake
        no obligation to update any forward-looking statement except to the
        extent required by applicable law.
        <br />
        <br />
      </>
    ),
  },
  {
    title: "WHISTLER PIPELINE CAPACITY EXPANSION",
    description:
      "AUSTIN, Texas ---- WhiteWater today announced the expansion of the Whistler Pipeline’s mainline capacity through the planned installation of three new compressor stations. The new compressor stations will increase the pipeline’s mainline capacity from 2.0 billion cubic feet per day (Bcf/d) to approximately 2.5 Bcf/d. The expansion is scheduled to be in service in September of 2023 and the expansion capacity will be offered at terms equivalent to initial shippers, including fuel retention. The Whistler Pipeline is owned by a consortium including MPLX LP (NYSE: MPLX), WhiteWater, and a joint venture between Stonepeak and West Texas Gas, Inc.",
    date: "May 02, 2022",
    fullDescription: (
      <>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About the Whistler Pipeline
        </Typography>
        The Whistler pipeline is an approximately 450-mile, 42-inch intrastate
        pipeline that transports natural gas from the Waha Header in the Permian
        Basin to Agua Dulce, Texas, providing direct access to South Texas and
        export markets. An approximately 85-mile 36-inch lateral provides
        connectivity to the Midland Basin.
        <br />
        <br />
        EnLink Midstream Partners Investor Relations: Brian Brungardt (214)
        721-9353
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. MPLX also owns crude oil and natural gas gathering systems
        and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater is an Austin, Texas based infrastructure company. WhiteWater
        is partnered with multiple private equity funds including but not
        limited to Ridgemont Equity Partners and First Infrastructure Capital.
        For more information about WhiteWater, visit www.wwdev.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Ridgemont Equity Partners
        </Typography>
        Ridgemont Equity Partners is a Charlotte-based middle market buyout and
        growth equity investor. Since 1993, the principals of Ridgemont have
        invested over $6.0 billion. The firm focuses on equity investments up to
        $250 million and utilizes a proven, industry-focused investment approach
        and repeatable value creation strategies. For more information about
        Ridgemont, visit www.ridgemontep.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About First Infrastructure Capital
        </Typography>
        First Infrastructure Capital Advisors, LLC is a Houston-based investment
        firm specializing in greenfield projects and companies operating in the
        midstream, downstream, electric power, telecommunications, and renewable
        energy industries. First Infrastructure Capital Advisors, LLC is an
        SEC-registered investment adviser, which manages funds affiliated with
        First Infrastructure Capital, L.P. For more information about First
        Infrastructure Capital, visit www.firstinfracap.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Stonepeak
        </Typography>
        Stonepeak is a leading alternative investment firm specializing in
        infrastructure and real assets with approximately $46 billion of assets
        under management. Through its investment in defensive, hard-asset
        businesses globally, Stonepeak aims to create value for its investors
        and portfolio companies, and to have a positive impact on the
        communities in which it operates. Stonepeak sponsors investment vehicles
        focused on private equity and credit. The firm provides capital,
        operational support, and committed partnership to sustainably grow
        investments in its target sectors, which include communications, energy
        transition, transport and logistics, and social infrastructure.
        Stonepeak is headquartered in New York with offices in Austin, Hong
        Kong, Houston, London and Sydney. For more information, please visit
        www.stonepeak.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
        MPLX LP Investor Relations: Kristina Kazarian (419) 421-2071
        <br />
        <br />
        WhiteWater Investor Relations: www.wwdev.com
        <br />
        <br />
        Stonepeak: Kate Beers (646) 540-5225
        <br />
        <br />
        Some of the above statements constitute forward-looking statements.
        Forward-looking statements are not guarantees of future performance and
        are subject to risks, uncertainties and other factors, some of which are
        beyond the control of the companies named herein and are difficult to
        predict. Factors that could impact the opportunities described above
        include but are not limited to general domestic and international
        economic and political conditions and the factors described in MPLX’s
        filings with the Securities and Exchange Commission (SEC). Any
        forward-looking statement speaks only as of the date of the applicable
        communication and the companies named herein undertake no obligation to
        update any forward-looking statement except to the extent required by
        applicable law. Copies of MPLX's Annual Report on Form 10-K, Quarterly
        Reports on Form 10-Q and other SEC filings are available on the SEC's
        website, MPLX's website at http://ir.mplx.com or by contacting MPLX's
        Investor Relations office.
        <br />
        <br />
      </>
    ),
  },
  {
    title: "WHISTLER PIPELINE EXPANSION",
    description:
      "AUSTIN, Texas ---- Whistler Pipeline LLC (“Whistler”) today announced the expansion of the Whistler pipeline’s Midland basin footprint with a new 36-inch lateral extending northwest into Martin County. The Martin County lateral will lengthen the existing 36-inch Midland lateral approximately 35 miles and connect to multiple processing sites in the county. The lateral is scheduled to be in service in the fourth quarter of 2022.",
    date: "January 06, 2022",
    fullDescription: (
      <>
        Whistler is owned by a consortium including MPLX LP (NYSE: MPLX),
        WhiteWater, and a joint venture between Stonepeak and West Texas Gas,
        Inc. (WTG).
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About the Whistler Pipeline
        </Typography>
        The Whistler pipeline is an approximately 450-mile, 42-inch intrastate
        pipeline that transports natural gas from the Waha Header in the Permian
        Basin to Agua Dulce, Texas, providing direct access to South Texas and
        export markets. An approximately 85-mile 36-inch lateral provides
        connectivity to the Midland Basin.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. MPLX also owns crude oil and natural gas gathering systems
        and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater is a management owned, Austin based midstream company.
        WhiteWater is partnered with multiple private equity funds including but
        not limited to Ridgemont Equity Partners and First Infrastructure
        Capital. Since inception, WhiteWater has reached final investment
        decision on ~$3 billion in greenfield development projects. For more
        information about WhiteWater, visit www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Ridgemont Equity Partners
        </Typography>
        Ridgemont Equity Partners is a Charlotte-based middle market buyout and
        growth equity investor. Since 1993, the principals of Ridgemont have
        invested over $5.5 billion. The firm focuses on equity investments up to
        $250 million and utilizes a proven, industry-focused investment approach
        and repeatable value creation strategies. For more information about
        Ridgemont, visit www.ridgemontep.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About First Infrastructure Capital
        </Typography>
        First Infrastructure Capital Advisors, LLC is a Houston-based investment
        firm specializing in greenfield projects and companies operating in the
        midstream, downstream, electric power, telecommunications, and renewable
        energy industries. First Infrastructure Capital Advisors, LLC is an
        SEC-registered investment adviser, which manages funds affiliated with
        First Infrastructure Capital, L.P. For more information about First
        Infrastructure Capital, visit www.firstinfracap.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Stonepeak
        </Typography>
        Stonepeak is a leading alternative investment firm specializing in
        infrastructure and real assets with approximately $43.5 billion of
        assets under management. Through its investment in defensive, hard-asset
        businesses globally, Stonepeak aims to create value for its investors
        and portfolio companies, and to have a positive impact on the
        communities in which it operates. Stonepeak sponsors investment vehicles
        focused on private equity and credit. The firm provides capital,
        operational support, and committed partnerships to sustainably grow
        investments in its target sectors, which include transport and
        logistics, communications, water, energy transition, and power and
        renewable energy. Stonepeak is headquartered in New York with offices in
        Austin, Hong Kong, Houston, and Sydney. For more information, please
        visit https://stonepeakpartners.com/.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WTG
        </Typography>
        WTG (together with its affiliates) comprises a family of related natural
        gas midstream and downstream entities headquartered in Midland, Texas
        since 1976 with operations in more than 90 Texas and Oklahoma counties.
        These WTG entities operate more than 900 MMcfd of gas processing
        capacity with more than 9,000 miles of gathering systems, 1,800 miles of
        transmission pipelines and distribution systems serving approximately
        25,000 LDC customers.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
        MPLX LP Investor Relations: Kristina Kazarian (419) 421-2071
        <br />
        <br />
        WhiteWater Investor Relations: www.whitewatermidstream.com
        <br />
        <br />
        Stonepeak: Kate Beers (646) 540-5225
        <br />
        <br />
        WTG Investor Relations: www.westtexasgas.com
        <br />
        <br />
        Some of the above statements constitute forward-looking statements.
        Forward-looking statements are not guarantees of future performance and
        are subject to risks, uncertainties and other factors, some of which are
        beyond the control of the companies named herein and are difficult to
        predict. Factors that could impact the opportunities described above
        include but are not limited to general domestic and international
        economic and political conditions and the factors described in MPLX’s
        filings with the Securities and Exchange Commission (SEC). Any
        forward-looking statement speaks only as of the date of the applicable
        communication and the companies named herein undertake no obligation to
        update any forward-looking statement except to the extent required by
        applicable law. Copies of MPLX's Annual Report on Form 10-K, Quarterly
        Reports on Form 10-Q and other SEC filings are available on the SEC's
        website, MPLX's website at http://ir.mplx.com or by contacting MPLX's
        Investor Relations office.
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "BANGEL Adds Rattler Midstream LP as Strategic Partner Following Fourth Quarter 2021 In-Service Date",
    description:
      "AUSTIN, Texas--(BUSINESS WIRE)--WhiteWater Midstream (WhiteWater), MPLX LP (MPLX NYSE: MPLX), and West Texas Gas (WTG) are pleased to announce the addition of a fourth partner, Rattler Midstream LP (Rattler NASDAQ: RTLR), to the companies’ BANGL,LLC joint venture(BANGL).",
    date: "January 24, 2022",
    fullDescription: (
      <>
        Concurrent with Rattler’s investment in the project,Diamondback Energy,
        Inc. (NASDAQ: FANG),Rattler’s upstream operating parent, entered into a
        significant, long-termnatural gas liquids (NGL)product dedication
        withBANGL.
        <br />
        <br />
        BANGLbegan full commercial service in the fourth quarter of 2021 and
        provides NGL takeaway capacity from MPLX and WTG gas processing plants
        in the Permian Basin to the NGL fractionation hub in Sweeny, Texas.
        WhiteWater’sinvestment in BANGL is backed by Ridgemont Equity Partners,
        Denham Capital Management and the Ontario Power Generation Inc. Pension
        Plan.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About BANGL, LLC
        </Typography>
        BANGL is a joint venture between White Water, MPLX, WTG, and Rattler.
        The natural gas liquids pipeline system connects the Delaware and
        Midland basins of Texas to the fractionation market in Sweeny, Texas and
        has expansion capacity of up to300,000 barrels per day.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. The company also owns crude oil and natural gas gathering
        systems and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater is a management owned, Austin based midstream company.
        WhiteWater is partnered with multiple private equity funds including but
        not limited to Ridgemont Equity Partners, Denham Capital Management,
        First Infrastructure Capital and the Ontario Power Generation Inc.
        Pension Plan. Since inception, WhiteWater has reached final investment
        decision on ~$3 billion in greenfield development projects. For more
        information about WhiteWater, visit www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WTG
        </Typography>
        WTG (together with its affiliates) comprises a family of related natural
        gas midstream and downstream entities headquartered in Midland, Texas
        since 1976 with operations in more than 90 Texas and Oklahoma counties.
        These WTG entities operate more than 900 MMcfd of gas processing
        capacity with more than 9,000 miles of gathering systems, 1,800 miles of
        transmission pipelines and distribution systems serving approximately
        25,000 LDC customers.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Rattler Midstream LP
        </Typography>
        Rattler is a Delaware limited partnership formed by Diamondback Energy,
        Inc. to own, operate, develop and acquire midstream and energy-related
        infrastructure assets. Rattler owns crude oil, natural gas and
        water-related midstream assets in the Permian Basin that provide
        services to Diamondback Energy, Inc. and third party customers under
        primarily long-term, fixed-fee contracts. For more information, please
        visit www.rattlermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Diamondback Energy, Inc.
        </Typography>
        Diamondback Energy, Inc. is an independent oil and natural gas company
        headquartered in Midland, Texas focused on the acquisition, development,
        exploration and exploitation of unconventional, onshore oil and natural
        gas reserves in the Permian Basin in West Texas. For more information,
        please visit www.diamondbackenergy.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Ridgemont Equity Partners
        </Typography>
        Ridgemont Equity Partners (Ridgemont) is a Charlotte-based middle market
        buyout and growth equity investor. Since 1993, the principals of
        Ridgemont have invested over $5.5 billion. The firm focuses on equity
        investments up to $250 million and utilizes a proven, industry-focused
        investment approach and repeatable value creation strategies. For more
        information about Ridgemont, visit www.ridgemontep.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Denham Capital Management
        </Typography>
        Denham Capital Management (Denham) is a leading energy and
        resources-focused global private equity firm with more than $12 billion
        of invested and committed capital across multiple fund vehicles and
        offices in Houston, Boston, Toronto, London and Perth. The firm makes
        direct investments in the energy and resources sectors, including
        businesses involving energy resources, sustainable infrastructure and
        mining, across the globe and all stages of the corporate lifecycle.
        Denham’s investment professionals apply deep technical, operational and
        industry experience and work in close partnership with management teams
        to achieve long-term investment objectives. For more information about
        Denham Capital, visit www.denhamcapital.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
        MPLX Investor Relations: Kristina Kazarian (419) 421-2071
        <br />
        <br />
        WhiteWater Investor Relations: www.whitewatermidstream.com
        <br />
        <br />
        WTG Investor Relations: www.westtexasgas.com
        <br />
        <br />
        Rattler Investor Relations: Jared Carameros (432) 247-6213
        <br />
        <br />
      </>
    ),
  },
  {
    title: "WhiteWater Announces Acquisition of Sendero’s Gateway Pipeline",
    description:
      "AUSTIN, Texas--(BUSINESS WIRE)--WhiteWater today announced the acquisition of Sendero’s Gateway Pipeline. The 24-inch Gateway Pipeline is connected to WhiteWater’s Agua Blanca pipeline system, The Gateway Pipeline extends approximately 24 miles north from Reeves County into the Carlsbad Area.",
    date: "August 26, 2021",
    fullDescription: (
      <>
        WhiteWater’s investment in Agua Blanca is led by First Infrastructure
        Capital. Inquiries regarding the Agua Blanca pipeline system should be
        directed to ab@wwm-llc.com.
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater is an Austin based infrastructure company founded in 2016.
        WhiteWater has partnered with multiple private equity funds and direct
        investors since inception.
        <br />
        <br />
        For more information about WhiteWater, visit
        www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About First Infrastructure Capital
        </Typography>
        First Infrastructure Capital Advisors, LLC is a Houston-based investment
        firm specializing in greenfield projects and companies operating in the
        midstream, downstream, electric power, telecommunications, and renewable
        energy industries. First Infrastructure Capital Advisors, LLC is an
        SEC-registered investment adviser, which manages funds affiliated with
        First Infrastructure Capital, L.P. For more information about First
        Infrastructure Capital, visit www.firstinfracap.com.
        <br />
        <br />
      </>
    ),
  },
  {
    title: "Whistler Pipeline Placed in Service",
    description:
      "AUSTIN, Texas--(BUSINESS WIRE)--The Whistler Pipeline began full commercial service on July 1st, 2021 providing approximately 2.0 billion cubic feet per day (Bcf/d) of incremental natural gas transport capacity to the Texas Gulf Coast markets from the Permian basin, which will help ensure sufficient reliable gas takeaway and reduce natural gas flaring in the Permian basin. The delivery points in the Agua Dulce provide shippers with access to Gulf Coast industrial and export markets including LNG.",
    date: "August 02, 2021",
    fullDescription: (
      <>
        The Whistler Pipeline is 100% owned by Whistler Pipeline LLC, which is a
        consortium made up of MPLX LP (NYSE: MPLX), WhiteWater and a joint
        venture between Stonepeak Infrastructure Partners (Stonepeak) and West
        Texas Gas, Inc. (WTG).
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT THE WHISTLER PIPELINE
        </Typography>
        The Whistler Pipeline is an approximately 450-mile, 42-inch intrastate
        pipeline that transports natural gas from the Waha Header in the Permian
        Basin to Agua Dulce, Texas, providing direct access to South Texas and
        export markets. An approximately 50-mile 36-inch lateral provides
        connectivity to the Midland Basin.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets, and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. The company also owns crude oil and natural gas gathering
        systems and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater
        </Typography>
        WhiteWater is an Austin-based independent midstream company providing
        transportation services to domestic oil and gas plays. For more
        information about WhiteWater, visit www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Stonepeak Infrastructure Partners
        </Typography>
        Stonepeak Infrastructure Partners (www.stonepeakpartners.com) is an
        infrastructure-focused private equity firm with over $15 billion of
        assets under management and with offices in New York, Houston and
        Austin. Stonepeak invests in long-lived, hard-asset businesses and
        projects that provide essential services to customers, and seeks to
        actively partner with high-quality management teams, facilitate
        operational improvements, and provide capital for growth initiatives.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WTG
        </Typography>
        WTG (West Texas Gas, Inc. & affiliates) is composed of a family of
        related natural gas midstream and downstream entities headquartered in
        Midland, TX since 1976 with operations in more than 90 Texas and
        Oklahoma counties. These WTG entities operate more than 700 MMcf/d of
        gas processing capacity with more than 10,000 miles of gathering
        systems, 1,800 miles of transmission pipelines and distribution systems
        serving approximately 25,000 LDC customers.
        <br />
        <br />
        This press release contains forward-looking statements within the
        meaning of the federal securities laws. These forward-looking statements
        relate to, among other things, statements with respect to forecasts
        regarding capacity, rates, incremental investment, market conditions and
        the market and operational opportunities discussed above. You can
        identify forward-looking statements by words such as "anticipate,"
        "design," "estimate," "expect," "forecast," "plan," "project,"
        "potential," "target," "could," "may," "should," "would," "will" or
        other similar expressions that convey the uncertainty of future events
        or outcomes. Such forward-looking statements are not guarantees of
        future performance and are subject to risks, uncertainties and other
        factors, some of which are beyond the control of the companies and are
        difficult to predict. Factors that could impact the opportunities
        described above are set forth under the heading "Risk Factors" in MPLX's
        Annual Report on Form 10-K for the year ended Dec. 31, 2020, and
        Quarterly Reports on Form 10-Q, filed with the Securities and Exchange
        Commission (SEC). In addition, the forward-looking statements included
        herein could be affected by general domestic and international economic
        and political conditions. Unpredictable or unknown factors not discussed
        here or in MPLX's Form 10-K, Forms 10-Q and other filings with the SEC
        could also have material adverse effects on forward-looking statements.
        Any forward-looking statements speak only as of the date of the
        applicable communication and the companies undertake no obligation to
        update any forward-looking statements except to the extent required by
        applicable law. Copies of MPLX's Form 10-K, Forms 10-Q and other SEC
        filings are available on the SEC's website, MPLX's website at
        http://ir.mplx.com or by contacting MPLX's Investor Relations office.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
        MPLX LP Investor Relations: Kristina Kazarian (419) 421-2071
        <br />
        <br />
        WhiteWater Investor Relations: www.whitewatermidstream.com
        <br />
        <br />
        Stonepeak Infrastructure Partners Investor Relations: Dan Schmitz (212)
        907-5119
        <br />
        <br />
        WTG Investor Relations: David B. Freeman (432) 682-4349
        <br />
        <br />
      </>
    ),
  },
  {
    title: "WhiteWater Announces Open Season at its Waha Gas Storage Facility",
    description:
      "AUSTIN--(BUSINESS WIRE)—WhiteWater Midstream, LLC (“WhiteWater”), today announced an open season to solicit commitments for firm natural gas storage services at the Waha Gas Storage (“WGS”) facility in Pecos County, Texas. WhiteWater is offering interested parties an opportunity to execute storage service agreements for up to 2 Bcf of firm storage capacity under firm intrastate or 311 storage services. Service for the capacity contracted under this open season is anticipated to commence on or around July 1, 2022.",
    date: "April 12, 2021",
    fullDescription: (
      <>
        OPEN SEASON PROCEDURE AND DURATION
        <br />
        <br />
        The open season will commence on Monday, April 12, 2021 and conclude at
        5:00 PM CDT on Friday May 21, 2021. WhiteWater may, in its sole
        discretion, extend the duration of the open season at any time.
        <br />
        <br />
        Any customer desiring firm storage services at the WGS facility must
        complete the Service Request Form (the “Service Request”) attached to
        the open season notice found at http://whitewatermidstream.com/projects.
        The Service Request must be signed by a duly authorized representative
        of the requesting customer, together with requesting customer’s
        completed nondisclosure agreement. All submittals should be sent via
        email to wgs@wwm-llc.com. Service Requests made during the open season
        will be subject to the negotiation and execution of a binding storage
        services agreement, which will be shared with interested parties upon
        receipt and review of the Service Requests.
        <br />
        <br />
        Prior to close of the Open Season, Parties may submit questions
        concerning the Open Season to wgs@wwm-llc.com. WhiteWater will post any
        clarifications provided in response to submitted inquiries at
        www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT THE WAHA GAS STORAGE FACILITY
        </Typography>
        WhiteWater’s Waha Gas Storage facilities are underground salt caverns
        located in Pecos County, Texas, immediately adjacent to the Agua Blanca
        header system. The Waha Gas Storage facilities will be connected to Agua
        Blanca’s Waha header system and natural gas may be physically received
        from or delivered to the interconnecting facilities on the header. With
        six existing caverns and five additional permitted caverns, the
        facilities can provide approximately 10 Bcf of storage capacity once
        fully developed.
        <br />
        <br />
        The 2 Bcf of storage capacity will be associated with 200,000 MMBtu/d of
        Maximum Daily Injection Quantities (“MDIQ”) and Maximum Daily Withdrawal
        Quantities (“MDWQ”). Each customer will be granted MDIQ and MDWQ rights
        pro-rated to their total capacity commitments.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT WHITEWATER MIDSTREAM
        </Typography>
        WhiteWater Midstream is a management owned, Austin based infrastructure
        company. WhiteWater Midstream is partnered with multiple private equity
        funds including but not limited to Ridgemont Equity Partners, Denham
        Capital Management, First Infrastructure Capital and the Ontario Power
        Generation Inc. Pension Plan. Since inception, WhiteWater has reached
        final investment decision on ~$3 billion in greenfield development
        projects. For more information about WhiteWater Midstream, visit
        www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
        WhiteWater Midstream
        <br />
        <br />
        Caleb Ryan
        <br />
        <br />
        Senior Vice President of Operations
        <br />
        <br />
        (512) 953-2105
        <br />
        <br />
        wgs@wwm-ll.com
        <br />
        <br />
        Bryan Willoughby
        <br />
        <br />
        Director Business Development
        <br />
        <br />
        (512) 953-2107
        <br />
        <br />
        wgs@wwm-llc.com
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "WhiteWater Midstream and MPLX LP Announce Substantial Completion of Agua Blanca Pipeline System Expansion",
    description:
      "AUSTIN, Texas ---- WhiteWater Midstream (WWM) and MPLX LP (NYSE: MPLX) today announced the substantial completion of a 1.8 billion cubic-feet-per-day (Bcf/d) expansion of their joint venture Agua Blanca pipeline system. Testing and commissioning of the expansion will begin this month, and the system is anticipated to be brought into full service in early 2021.",
    date: "November 11, 2020",
    fullDescription: (
      <>
        The Agua Blanca system is connected to almost 20 gas processing sites in
        the Delaware Basin and is currently transporting gas produced in
        Culberson, Loving, Reeves, Pecos, Winkler and Ward counties in Texas,
        and Eddy and Lea counties in New Mexico, to the Waha Hub. The Agua
        Blanca expansion includes a 42-inch diameter trunk line that more than
        doubles system capacity to over 3 Bcf/d while providing significant
        incremental takeaway capacity for plants servicing Texas and New Mexico
        gas producers.
        <br />
        <br />
        “We are excited to bring this expansion into service ahead of schedule
        while continuing to provide reliable and transparent transportation
        services to producers and processors in Texas and New Mexico,” said
        WhiteWater Chief Executive Officer Christer Rundlof. “WWM remains
        committed to developing premier Permian basin residue assets as markets
        normalize and growth resumes.”
        <br />
        <br />
        WhiteWater Midstream’s investment in the Agua Blanca joint venture is
        led by First Infrastructure Capital. Inquiries regarding the Agua Blanca
        Expansion should be directed to ab@wwm-llc.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater Midstream
        </Typography>
        WhiteWater Midstream is a management owned, Austin based midstream
        company. WhiteWater Midstream is partnered with multiple private equity
        funds including but not limited to Ridgemont Equity Partners, Denham
        Capital Management, First Infrastructure Capital and the Ontario Power
        Generation Inc. Pension Plan. Since inception, WhiteWater has reached
        final investment decision on ~$3 billion in greenfield development
        projects. For more information about WhiteWater Midstream, visit
        www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. The company also owns crude oil and natural gas gathering
        systems and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About First Infrastructure Capital
        </Typography>
        First Infrastructure Capital Advisors, LLC is a Houston-based investment
        firm specializing in greenfield projects and companies operating in the
        midstream, downstream, electric power, telecommunications, and renewable
        energy industries. First Infrastructure Capital Advisors, LLC is an
        SEC-registered investment adviser, which manages funds affiliated with
        First Infrastructure Capital, L.P. For more information about First
        Infrastructure Capital, visit www.firstinfracap.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Investor Relations Contacts:
        </Typography>
        WhiteWater Midstream
        <br />
        Bryan Willoughby
        <br />
        Director, Business Development
        <br />
        (512) 953-2100
        <br />
        whitewatermidstream
        <br />
        <br />
        MPLX
        <br />
        Kristina Kazarian
        <br />
        Vice President, Investor Relations
        <br />
        (419) 421-2071
        <br />
        <br />
        This press release contains forward-looking statements within the
        meaning of the federal securities laws. These forward-looking statements
        relate to, among other things, statements with respect to forecasts
        regarding capacity, rates, incremental investment, market conditions and
        timing for becoming operational for the opportunities discussed above.
        You can identify forward-looking statements by words such as
        "anticipate," "design," "estimate," "expect," "forecast," "plan,"
        "project," "potential," "target," "could," "may," "should," "would,"
        "will" or other similar expressions that convey the uncertainty of
        future events or outcomes. Such forward-looking statements are not
        guarantees of future performance and are subject to risks, uncertainties
        and other factors, some of which are beyond the control of the companies
        and are difficult to predict. Factors that could impact the
        opportunities described above are set forth under the heading "Risk
        Factors" in MPLX's Annual Report on Form 10-K for the year ended Dec.
        31, 2019, and Quarterly Reports on Form 10-Q, filed with the Securities
        and Exchange Commission (SEC). In addition, the forward-looking
        statements included herein could be affected by general domestic and
        international economic and political conditions. Unpredictable or
        unknown factors not discussed here or in MPLX's Forms 10-K and 10-Q
        could also have material adverse effects on forward-looking statements.
        Copies of MPLX's Forms 10-K and 10-Q are available on the SEC's website,
        MPLX's website at http://ir.mplx.com or by contacting MPLX's Investor
        Relations office.
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "WhiteWater Midstream Announces Strategic Joint Venture to provide Permian Basin NGL Transportation Solutions",
    description:
      "Austin, Texas - WhiteWater Midstream (WhiteWater), MPLX LP (NYSE: MPLX) and West Texas Gas, Inc. (WTG) have recently formed a Joint Venture (JV) to provide natural gas liquids (NGLs) takeaway capacity from MPLX and WTG gas processing plants in the Permian Basin to the NGL fractionation hub in Sweeny, TX. WhiteWater Midstream’s Investment in the JV is backed by Ridgemont Equity Partners, Denham Capital Management and the Ontario Power Generation Inc. Pension Plan.",
    date: "August 06, 2020",
    fullDescription: (
      <>
        The JV will provide an optimized approach to pipeline transportation
        service for NGLs primarily through the utilization of existing
        infrastructure with limited initial construction. The solution will
        facilitate future, capital-efficient expansions that meet customer
        demands in a recovering basin. The JV is supported by volumes from key
        processing plants with long-term commitments from top-tier Permian
        producers.
        <br />
        <br />
        As part of this NGL transportation solution, the JV has entered into
        multiple capacity arrangements from Orla, TX to Sweeny, TX including an
        agreement with EPIC Y-Grade Pipeline LP (EPIC) to own an undivided joint
        interest (UJI) in EPIC’s existing 24” NGL pipeline from West Texas to
        the Eagle Ford Basin.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater Midstream
        </Typography>
        WhiteWater Midstream is a management owned, Austin based midstream
        company. WhiteWater Midstream is partnered with multiple private equity
        funds including but not limited to Ridgemont Equity Partners, Denham
        Capital Management, First Infrastructure Capital and the Ontario Power
        Generation Inc. Pension Plan. Since inception, WhiteWater has reached
        final investment decision on ~$3 billion in greenfield development
        projects. For more information about WhiteWater Midstream, visit
        www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. The company also owns crude oil and natural gas gathering
        systems and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WTG
        </Typography>
        WTG (West Texas Gas, Inc. & affiliates) is composed of a family of
        related natural gas midstream and downstream entities headquartered in
        Midland, TX since 1976 with operations in more than 90 Texas and
        Oklahoma counties. These WTG entities operate more than 900 MMcfd + of
        gas processing capacity with more than 10,000 miles of gathering
        systems, 1,800 miles of transmission pipelines and distribution systems
        serving approximately 25,000 LDC customers.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Ridgemont Equity Partners
        </Typography>
        Ridgemont Equity Partners is a Charlotte-based middle market buyout and
        growth equity investor. Since 1993, the principals of Ridgemont have
        invested approximately $5.0 billion. The firm focuses on equity
        investments up to $250 million in industries in which it has deep
        expertise, including business and industrial services, energy and
        sustainable strategies, healthcare, and technology and
        telecommunications. For more information about Ridgemont Equity
        Partners, visit www.ridgemontep.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Denham Capital Management
        </Typography>
        Denham Capital is a leading energy and resources-focused global private
        equity firm with more than $9.7 billion of committed capital across
        eleven fund vehicles and offices in Houston, Boston, London and Perth.
        The firm makes direct investments in the energy and resources sectors,
        including businesses involving oil and gas, power generation and mining,
        across the globe and all stages of the corporate lifecycle. Denham’s
        investment professionals apply deep technical, operational and industry
        experience and work in close partnership with management teams to
        achieve long-term investment objectives. For more information about
        Denham Capital, visit www.denhamcapital.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Investor Relations Contacts:
        </Typography>
        MPLX: Kristina Kazarian, Vice President, Investor Relations, (419)
        421-2071
        <br />
        <br />
        WhiteWater Midstream: www.whitewatermidstream.com
        <br />
        <br />
        WTG: David B. Freeman, VP of Business Development, (432) 682-4349
        <br />
        <br />
        MPLX Media Contacts:
        <br />
        <br />
        Hamish Banks, Vice President, Communications (419) 421-2521
        <br />
        <br />
        Jamal Kheiry, Manager, Communications (419) 421-3312
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "Whistler Pipeline Consortium Announces Binding Open Season for the Whistler Pipeline",
    description:
      "AUSTIN, Texas — (BUSINESS WIRE) — Whistler Pipeline LLC (“Whistler Pipeline”) today announced the beginning of a binding Open Season to solicit commitments for the remaining capacity on the Whistler Pipeline. The Whistler Pipeline will transport approximately 2 billion cubic feet per day (Bcf/d) of natural gas through approximately 450 miles of 42-inch pipeline from Waha, Texas, to the Agua Dulce area in South Texas",
    date: "November 15, 2019",
    fullDescription: (
      <>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          OPEN SEASON PROCEDURE AND DURATION
        </Typography>
        The Open Season will commence on Friday, November 15, 2019 and conclude
        at 5:00 PM CST on Monday December 16, 2019. Whistler Pipeline may, at
        its sole discretion, extend the duration of the Open Season at any time
        during the Open Season.
        <br />
        <br />
        Whistler Pipeline asks that interested shippers submit, confidentially,
        a completed Service Request Form.The Service Request form, along with
        additional information regarding the Whistler Pipeline can be found at
        http://whitewatermidstream.com/operations.
        <br />
        <br />
        Prior to close of the Open Season, Parties may submit questions
        concerning the Open Season to whistler@wwm-llc.com. WhiteWater
        Midstream, one of the consortium members, will post any clarifications
        provided in response to submitted inquiries at
        www.whitewatermidstream.com.
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT THE WHISTLER PIPELINE
        </Typography>
        The Whistler Pipeline is an approximately 450-mile, 42-inch intrastate
        pipeline (the “Whistler Mainline”) that will transport natural gas from
        an interconnect with the Waha Header near Coyanosa, Texas in the Permian
        Basin to a terminus near Agua Dulce, Texas, providing direct access to
        South Texas markets and consumers. An approximately 50-mile 36-inch
        lateral will provide connectivity for gas processors in the Midland
        Basin. The pipeline will have transportation capacity of 2 billion cubic
        feet per day (Bcf/d).
        <br />
        <br />
        Whistler Pipeline has contracted for the supply of the entirety of
        42”/36” steel pipeline needed to complete the project and is on schedule
        in its completion of survey along the planned route and acquisition of
        appropriate permits for a summer 2021 in-service date.Construction will
        commence in 2020.
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Whistler Pipeline LLC
        </Typography>
        Whistler Pipeline LLC is a consortium made up of MPLX LP (NYSE: MPLX),
        WhiteWater Midstream (Whitewater) and a joint venture between Stonepeak
        Infrastructure Partners (Stonepeak) and West Texas Gas, Inc. (WTG).
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets, and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. The company also owns crude oil and natural gas gathering
        systems and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater Midstream
        </Typography>
        WhiteWater Midstream is an Austin-based independent midstream company
        providing transportation services to domestic oil and gas plays. For
        more information about WhiteWater Midstream, visit
        www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About First Infrastructure Capital
        </Typography>
        First Infrastructure Capital Advisors, LLC is a Houston-based investment
        firm specializing in greenfield projects and companies operating in the
        midstream, downstream, electric power, telecommunications, and renewable
        energy industries. First Infrastructure Capital Advisors, LLC is an
        SEC-registered investment adviser, which manages funds affiliated with
        First Infrastructure Capital, L.P. For more information about First
        Infrastructure Capital, visit www.firstinfracap.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Stonepeak Infrastructure Partners
        </Typography>
        Stonepeak Infrastructure Partners (www.stonepeakpartners.com) is an
        infrastructure-focused private equity firm with over $15 billion of
        assets under management and with offices in New York, Houston and
        Austin. Stonepeak invests in long-lived, hard-asset businesses and
        projects that provide essential services to customers, and seeks to
        actively partner with high-quality management teams, facilitate
        operational improvements, and provide capital for growth initiatives.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WTG
        </Typography>
        WTG (West Texas Gas, Inc. & affiliates) is composed of a family of
        related natural gas midstream and downstream entities headquartered in
        Midland, TX since 1976 with operations in more than 90 Texas and
        Oklahoma counties.These WTG entities operate more than 700 MMcf/d of gas
        processing capacity with more than 10,000 miles of gathering systems,
        1,800 miles of transmission pipelines and distribution systems serving
        approximately 25,000 LDC customers.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
        MPLX LP Investor Relations: Kristina Kazarian (419) 421-2071
        <br />
        <br />
        WhiteWater Midstream Investor Relations: www.whitewatermidstream.com
        <br />
        <br />
        Stonepeak Infrastructure Partners Investor Relations: Dan Schmitz (212)
        907-5119
        <br />
        <br />
        WTG Investor Relations: David B. Freeman (432) 682-4349
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "WhiteWater Midstream Announces Major Expansion of the Agua Blanca System",
    description:
      "AUSTIN, Texas--Joint venture partners WhiteWater Midstream (“WhiteWater”), MPLX LP (NYSE: MPLX), alongside Agua Blanca lead investor First Infrastructure Capital, today announced final investment decision to proceed with the expansion of the Delaware Basin Agua Blanca System. The expansion includes a 42” diameter trunkline that more than doubles system capacity to over 3 billion cubic feet per day of natural gas across Culberson, Loving, Pecos, Reeves, Winkler and Ward counties. The expansion is supported by multiple 10 year, take-or-pay transportation agreements.",
    date: "November 06, 2019",
    fullDescription: (
      <>
        Inquiries regarding the Agua Blanca Expansion should be directed to
        ab@wwm-llc.com.
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater Midstream
        </Typography>
        WhiteWater Midstream is an Austin-based independent midstream company
        providing transportation services to domestic oil and gas plays. For
        more information about WhiteWater Midstream, visit
        www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About First Infrastructure Capital
        </Typography>
        First Infrastructure Capital Advisors, LLC is a Houston-based investment
        firm specializing in greenfield projects and companies operating in the
        midstream, downstream, electric power, telecommunications, and renewable
        energy industries. First Infrastructure Capital Advisors, LLC is an
        SEC-registered investment adviser, which manages funds affiliated with
        First Infrastructure Capital, L.P. For more information about First
        Infrastructure Capital, visit www.firstinfracap.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets, and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. The company also owns crude oil and natural gas gathering
        systems and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Contacts
        </Typography>
        WhiteWater Midstream Investor Relations: www.whitewatermidstream.com
        <br />
        <br />
        MPLX LP Investor Relations: Kristina Kazarian (419) 421-2071
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "MPLX, WhiteWater Midstream, and Stonepeak/WTG Proceeding with Whistler Pipeline Project",
    description:
      "FINDLAY, Ohio, June 3, 2019 – MPLX LP (NYSE: MPLX), WhiteWater Midstream (WhiteWater) backed by First Infrastructure Capital, and a joint venture between Stonepeak Infrastructure Partners (Stonepeak) andWest Texas Gas, Inc. (WTG) have reached a final investment decision to move forward with the design and construction of the Whistler Pipeline after having secured sufficient firm transportation agreements with shippers. The majority of available capacity on the planned pipeline has been subscribed and committed by long-term transportation agreements. WhiteWater and MPLX expect that the remaining capacity will be fully subscribed in coming months.",
    date: "June 05, 2019",
    fullDescription: (
      <>
        The Whistler Pipeline is being designed to transport approximately 2
        billion cubic feet per day (Bcf/d) of natural gas through approximately
        475 miles of 42-inch pipeline from Waha, Texas, to the Agua Dulce area
        in South Texas. Supply for the Whistler Pipeline would be sourced from
        multiple upstream connections in the Permian Basin, including direct
        connections to plants in the Midland Basin through an approximately 50
        mile, 30-inch pipeline lateral, as well as a direct connection to the
        1.4 Bcf/d Agua Blanca Pipeline, a joint venture between WhiteWater, MPLX
        and Targa. Agua Blanca Pipeline crosses through the heart of the
        Delaware Basin, including portions of Culberson, Loving, Pecos, Reeves,
        Winkler and Ward counties.
        <br />
        <br />
        “The decision to move forward with this project after securing
        sufficient commitments from shippers demonstrates our disciplined
        approach to investing,” said Gary R. Heminger, MPLX chairman and chief
        executive officer. “Whistler is expected to provide reliable residue gas
        transportation out of the Permian Basin, which is vital to our growing
        gas processing position and producers in the region.”
        <br />
        <br />
        “The WhiteWater team is excited to partner with MPLX and develop
        incremental transportation out of the Permian Basin, as production
        continues to dramatically outperform consensus estimates,” added
        Christer Rundlof, CEO of WhiteWater. “Whistler will tie directly into
        the most attractive markets in South Texas, including the growing LNG
        and Mexican markets.”
        <br />
        <br />
        The Whistler Pipeline is expected to be in Service in the third quarter
        of 2021, pending the receipt of customary regulatory and other
        approvals.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About MPLX LP
        </Typography>
        MPLX is a diversified, large-cap master limited partnership that owns
        and operates midstream energy infrastructure and logistics assets, and
        provides fuels distribution services. MPLX's assets include a network of
        crude oil and refined product pipelines; an inland marine business;
        light-product terminals; storage caverns; refinery tanks, docks, loading
        racks, and associated piping; and crude and light-product marine
        terminals. The company also owns crude oil and natural gas gathering
        systems and pipelines as well as natural gas and NGL processing and
        fractionation facilities in key U.S. supply basins. More information is
        available at www.MPLX.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater Midstream
        </Typography>
        WhiteWater Midstream is an Austin based, independent midstream company
        that provides transportation services to domestic oil and gas plays.
        WhiteWater was founded in 2016 and is backed by significant commitments
        from First Infrastructure Capital. For more information about WhiteWater
        Midstream, visit www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About First Infrastructure Capital
        </Typography>
        First Infrastructure Capital Advisors, LLC is a Houston-based investment
        firm specializing in greenfield projects and companies operating in the
        midstream, downstream, electric power, telecommunications, and renewable
        energy industries. First Infrastructure Capital Advisors, LLC is
        affiliated with Quanta Services and operated separately as an
        SEC-registered investment adviser, which manages funds affiliated with
        First Infrastructure Capital, L.P. For more information about First
        Infrastructure Capital, visit www.firstinfracap.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Stonepeak Infrastructure Partners
        </Typography>
        Stonepeak Infrastructure Partners (www.stonepeakpartners.com) is an
        infrastructure-focused private equity firm with over $15 billion of
        assets under management and with offices in New York, Houston and
        Austin. Stonepeak invests in long-lived, hard-asset businesses and
        projects that provide essential services to customers, and seeks to
        actively partner with high-quality management teams, facilitate
        operational improvements, and provide capital for growth initiatives.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WTG
        </Typography>
        WTG (West Texas Gas, Inc. & affiliates) is composed of a family of
        related natural gas midstream and downstream entities headquartered in
        Midland, TX since 1976 with operations in more than 90 Texas and
        Oklahoma counties. These WTG entities operate more than 700 MMcfd of gas
        processing capacity with more than 10,000 miles of gathering systems,
        1,800 miles of transmission pipelines and distribution systems serving
        approximately 25,000 LDC customers.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          CONTACTS
        </Typography>
        MPLX LP Investor Relations: Kristina Kazarian – (419) 421-2071
        <br />
        <br />
        WhiteWater Midstream Investor Relations: www.whitewatermidstream.com
        <br />
        <br />
        Stonepeak Infrastructure Partners Investor Relations: Dan Schmitz – +1
        (212) 907-5119
        <br />
        <br />
        WTG Investor Relations: David B. Freeman – 432-682-4349
        <br />
        <br />
        Forward-looking statements
        <br />
        <br />
        This press release contains forward-looking statements within the
        meaning of the federal securities laws. These forward-looking statements
        relate to, among other things, statements with respect to forecasts
        regarding capacity and timing for becoming operational for the
        opportunities discussed above. You can identify forward-looking
        statements by words such as "anticipate," "believe," "design,"
        "estimate," "expect," "forecast," "intend," "plan," "project,"
        "potential," "could," "may," "should," "would," "will" or other similar
        expressions that convey the uncertainty of future events or outcomes.
        Such forward-looking statements are not guarantees of future performance
        and are subject to risks, uncertainties and other factors, some of which
        are beyond the control of the companies and are difficult to predict.
        Although management of the respective companies believe any such
        statements are based on reasonable assumptions, there is no assurance
        that actual outcomes will not be materially different. Among the key
        risk factors associated with the project that may have a direct impact
        on completion of the project and construction of the pipeline or the
        pipeline's and the companies' results of operations and financial
        condition are: (1) the ability of the companies to negotiate and enter
        into definitive agreements and to obtain all required rights-of-way,
        permits and other approvals on a timely basis; (2) the ability to
        complete construction of the project on time and at expected costs; (3)
        price fluctuations and overall demand for natural gas; (4) changes in
        the pipeline's tariff rates or other terms as required by state or
        federal regulatory authorities; (5) the occurrence of an operational
        hazard or unforeseen interruption; (6) disruption in the debt and equity
        markets that negatively impacts the companies' abilities to finance
        capital spending and (7) willingness to incur or failure of customers or
        vendors to meet or continue contractual obligations related to this
        project. Additional information about issues that could lead to material
        changes in performance is set forth under the heading "Risk Factors" in
        MPLX's Annual Report on Form 10-K for the year ended Dec. 31, 2018,
        filed with the Securities and Exchange Commission (SEC). In addition,
        the forward-looking statements included herein could be affected by
        general domestic and international economic and political conditions.
        Unpredictable or unknown factors not discussed here or in MPLX's Form
        10-K could also have material adverse effects on forward-looking
        statements. Copies of MPLX's Form 10-K are available on the SEC website,
        MPLX's website at http://ir.mplx.com or by contacting MPLX's Investor
        Relations office. The companies undertake no obligation to revise these
        forward-looking statements to reflect events or circumstances occurring
        after today's date.
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "FIRST INFRASTRUCTURE CAPITAL ACQUIRES PREMIER DELAWARE BASIN PIPELINE THROUGH ACQUISITION OF WHITEWATER MIDSTREAM",
    description:
      "The Transaction Positions WhiteWater for the Long Term and Provides Substantial Capital for Future Projects",
    date: "February 04, 2019",
    fullDescription: (
      <>
        AUSTIN & HOUSTON, TEXAS – February 4, 2019 – WhiteWater Midstream
        (“WhiteWater”) and its financial sponsors, Denham Capital Management
        (“Denham”) and Ridgemont Equity Partners (“Ridgemont”), announced today
        that they have entered into definitive agreements for the sale of
        WhiteWater to funds managed by First Infrastructure Capital Advisors,
        LLC (“First Infrastructure Capital”).
        <br />
        <br />
        As part of the transaction, First Infrastructure Capital will acquire
        WhiteWater, including its 60% stake in Agua Blanca, a Delaware Basin
        intrastate natural gas pipeline system. Contemporaneous with this
        transaction, First Infrastructure Capital also entered into definitive
        agreements to acquire WPX Energy, Inc.’s 20% ownership stake in Agua
        Blanca. WhiteWater management will remain with the asset and is
        committed to growing Agua Blanca to service its customer base.Closing is
        expected in the first quarter of 2019 and is subject to customary
        approvals and closing conditions.
        <br />
        <br />
        Agua Blanca is a natural gas residue pipeline servicing the Delaware
        Basin. The system consists of ~90 miles of 36” diameter pipeline and 70
        miles of smaller diameter pipeline crossing portions of Culberson,
        Loving, Pecos, Reeves, Ward and Winkler counties. The initial capacity
        is ~1.4 Bcf/d with significant expansion plans underway. The asset is
        supported by multiple, substantial, long-term, take or pay contracts.
        The Agua Blanca system has a multitude of direct downstream connections
        which provide shippers on the system access to multiple markets.
        <br />
        <br />
        Christer Rundlof, CEO of WhiteWater said, “We are very excited about our
        new partnership with First Infrastructure Capital, who provides us with
        access to strong construction and operations expertise and access to
        significant capital to continue to expand the Agua Blanca system and
        execute other projects under development.WhiteWater is committed to
        continuing to partner with shippers in the Delaware Basin.Our new
        sponsor has a long investment horizon that will allow us to continue to
        manage the asset for the long term.”
        <br />
        <br />
        Christer continued, “We are grateful to have had Denham and Ridgemont as
        trusted partners. We appreciate their confidence in backing WhiteWater
        as a first-time team.Denham and Ridgemont’s energy sector expertise has
        been an important part of our team’s success. We are also extremely
        grateful to our joint venture partners and other anchor shippers who
        allowed us to grow the Agua Blanca system so quickly.MarkWest, Targa and
        WPX have been outstanding partners throughout the development of Agua
        Blanca. We look forward to the opportunity to work together on future
        projects.”
        <br />
        <br />
        Dan Shapiro, Managing Partner of First Infrastructure Capital Advisors,
        LLC said, “WhiteWater has created a unique cornerstone infrastructure
        asset that maximizes natural gas take-away options for producers in the
        Delaware Basin.We look forward to working closely with the team to
        invest significant capital in expansions of the Agua Blanca system,
        other natural gas residue, natural gas liquids, and complementary energy
        infrastructure projects.”
        <br />
        <br />
        Jordan Marye, Managing Partner of Denham said, “We congratulate the
        entire WhiteWater team on their success in building a progressive
        midstream business focused on delivering value to shippers in the
        Delaware Basin and beyond. We are excited to continue to partner with
        WhiteWater, First Infrastructure Capital and Ridgemont on future
        projects under development.”
        <br />
        <br />
        Tudor, Pickering, Holt & Co. and Credit Suisse Securities (USA) LLC
        acted as financial advisors to WhiteWater, Denham, and Ridgemont in
        connection with the transaction. Simmons Energy, A Division of Piper
        Jaffray, acted as the exclusive financial advisor to First
        Infrastructure Capital. Sidley Austin LLP acted as lead counsel for
        WhiteWater and Denham. Troutman Sanders LLP acted as lead counsel for
        Ridgemont.Latham & Watkins LLP acted as lead counsel for WhiteWater
        management.Milbank, Tweed, Hadley & McCloy LLP acted as lead counsel for
        First Infrastructure Capital.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About WhiteWater Midstream
        </Typography>
        WhiteWater Midstream is an Austin based, independent midstream company
        that provides transportation services to domestic oil and gas plays.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Denham Capital Management
        </Typography>
        Denham Capital is a leading energy and resources-focused global private
        equity firm with more than $9.7 billion of invested and committed
        capital across 10 fund vehicles and offices in Houston, London, Boston
        and Perth. The firm makes direct investments in the energy and resources
        sectors, including businesses involving oil and gas, power generation
        and mining, across the globe and all stages of the corporate lifecycle.
        Denham’s investment professionals apply deep operational and industry
        experience and work in partnership with management teams to achieve
        long-term investment objectives. For more information about Denham
        Capital, visit www.denhamcapital.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About Ridgemont Equity Partners
        </Typography>
        Ridgemont Equity Partners is a Charlotte-based middle market buyout and
        growth equity investor. Since 1993, the principals of Ridgemont have
        invested approximately $4.4 billion. The firm focuses on equity
        investments up to $250 million in industries in which it has deep
        expertise, including business and industrial services, energy,
        healthcare, and technology and telecommunications. www.ridgemontep.com
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          About First Infrastructure Capital
        </Typography>
        First Infrastructure Capital Advisors, LLC is a Houston-based investment
        firm specializing in greenfield projects and companies operating in the
        midstream, downstream, electric power, telecommunications, and renewable
        energy industries. First Infrastructure Capital Advisors, LLC is
        affiliated with Quanta Services and operated separately as an
        SEC-registered investment adviser, which manages funds affiliated with
        First Infrastructure Capital, L.P. For more information about First
        Infrastructure Capital, visit www.firstinfracap.com.
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "WhiteWater Midstream Adds Strategic Partners and New Commitments to Its Delaware Basin Agua Blanca Pipeline",
    description:
      "Austin, Texas – May 2, 2018 – WhiteWater Midstream today announced the execution of multiple transportation and interconnect agreements for its Delaware Basin Agua Blanca pipeline, a joint venture between WhiteWater and WPX Energy. In conjunction with those agreements, MarkWest Energy Partners, L.P., a wholly owned subsidiary of MPLX LP, and an affiliate of Targa Resources Corp. will join WhiteWater and WPX Energy as joint venture partners in the Agua Blanca pipeline. Approximately 80% of capital expenditures will be funded with project level debt. In addition to the agreements with its new partners, WhiteWater has entered into long-term transportation service agreements with two premier Delaware Basin producers in Culberson County.",
    date: "May 02, 2018",
    fullDescription: (
      <>
        Agua Blanca now has over 1.1 Bcf/d of long-term firm commitments.The
        pipeline has also continued to expand its connectivity with agreements
        in place with the following processors:Brazos Midstream, Crestwood
        Midstream Partners, EnLink Midstream Partners and Medallion
        Midstream.These agreements will bring total plant processing capacity
        connected to Agua Blanca to nearly 2.5 Bcf/d, which is expected to
        increase to 5.0 Bcf/d in the near-term.
        <br />
        <br />
        “We are extremely excited about our new strategic partners, MPLX and
        Targa.Both companies are top tier processors that continue to expand
        services in the Permian Basin.We look forward to growing Agua Blanca and
        derivative projects with our strong customer base,” said WhiteWater
        Chief Executive Officer Christer Rundlof.
        <br />
        <br />
        WhiteWater Midstream will own a 60 percent equity interest in Agua
        Blanca, with WPX Energy, MPLX and Targa owning an equity interest of
        20%, 10% and 10%, respectively in the project.
        <br />
        <br />
        Inquiries regarding Agua Blanca should be directed to ab@wwm-llc.com.
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT AGUA BLANCA
        </Typography>
        Agua Blanca is a natural gas residue pipeline servicing the Delaware
        Basin.The mainline consists of approximately 90 miles of 36” diameter
        pipeline and 70 miles of 12” to 24” diameter pipeline crossing portions
        of Culberson, Loving, Pecos, Reeves, Winkler, and Ward counties.The
        initial capacity is approximately 1.4 Bcf/d.The project is supported by
        multiple, substantial long-term, firm volume commitments.Agua Blanca
        will have multiple direct downstream connections including to the
        Trans-Pecos Header, El Paso Waha, ONEOK West Texas, Kinder Morgan’s Gulf
        Coast Express and Natural Gas Pipeline Company.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT WHITEWATER MIDSTREAM
        </Typography>
        WhiteWater Midstream is an Austin based, independent midstream company
        that provides transportation services to domestic oil and gas
        plays.WhiteWater was founded in 2016 and is backed by equity commitments
        from Denham Capital Management and Ridgemont Equity Partners.For more
        information about WhiteWater, visit www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT WPX ENERGY
        </Typography>
        WPX is an independent energy producer with core positions in the Permian
        and Williston basins. WPX’s production is approximately 80 percent
        oil/liquids and 20 percent natural gas. The company also has an emerging
        infrastructure portfolio in the Permian Basin.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT MPLX LP
        </Typography>
        MPLX is a diversified, growth-oriented master limited partnership formed
        in 2012 by Marathon Petroleum Corporation (MPC) to own, operate, develop
        and acquire midstream energy infrastructure assets. MPLX is engaged in
        the gathering, processing and transportation of natural gas; the
        gathering, transportation, fractionation, storage and marketing of NGLs;
        and the transportation, storage and distribution of crude oil and
        refined petroleum products through a marine fleet and approximately
        10,000 miles of crude oil and light product pipelines. Headquartered in
        Findlay, Ohio, MPLX's assets consist of a network of crude oil and
        products pipelines and supporting assets, including storage facilities
        (tank farms) located in the Midwest and Gulf Coast regions of the United
        States; 62 light-product terminals with approximately 24 million barrels
        of storage capacity; storage caverns with approximately 2.8 million
        barrels of storage capacity; a barge dock facility with approximately
        78,000 barrels per day of crude oil and product throughput capacity; and
        gathering and processing assets that include approximately 5.9 billion
        cubic feet per day of gathering capacity, 8.2 billion cubic feet per day
        of natural gas processing capacity and 610,000 barrels per day of
        fractionation capacity. In addition, MPLX provides fuels distribution
        services to MPC and owns refining logistics assets consisting of tanks
        with storage capacity of approximately 56 million barrels as well as
        refinery docks, loading racks and associated piping.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT TARGA RESOURCES CORP.
        </Typography>
        Targa Resources Corp. (NYSE: TRGP) is a leading provider of midstream
        services and is one of the largest independent midstream energy
        companies in North America. Targa owns, operates, acquires, and develops
        a diversified portfolio of complementary midstream energy assets. The
        Company is primarily engaged in the business of gathering, compressing,
        treating, processing and selling natural gas; storing, fractionating,
        treating, transporting, and selling NGLs and NGL products, including
        services to LPG exporters; gathering, storing, terminaling, and selling
        crude oil; storing, terminaling, and selling refined petroleum products.
        For more information, please visit www.targaresources.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT DENHAM CAPITAL
        </Typography>
        Denham Capital is a leading energy-focused global private equity firm
        with more than $8.4 billion of invested and committed capital across
        eight fund vehicles. The firm makes direct investments across all stages
        of the corporate lifecycle. Denham’s investment professionals apply deep
        operational and industry experience and work in partnership with
        management teams to achieve long-term investment objectives. For more
        information about Denham Capital, visit www.denhamcapital.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT RIDGEMONT EQUITY PARTNERS
        </Typography>
        Ridgemont Equity Partners is a Charlotte-based middle market buyout and
        growth equity investor. Since 1993, the principals of Ridgemont have
        invested over $3.5 billion in 136 companies. The firm focuses on
        investments of $25 million to $100 million in industries in which it has
        deep expertise, including basic industries and services, energy,
        healthcare and telecommunications/media/technology. For more information
        about Ridgemont, visit www.ridgemontep.com.
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "WhiteWater Midstream Announces Commencement of Operations for the Agua Blanca Pipeline",
    description:
      "Agua Blanca commenced commercial operations on April 1, 2018 putting in service 72 miles of 36” diameter pipe with a capacity of 1.4 Bcf/d.The pipeline runs from Orla, Texas to the Waha Hub, servicing portions of Culberson, Loving, Pecos, Reeves and Ward counties with multiple direct downstream connections including to the Trans-Pecos Header.",
    date: "April 11, 2018",
    fullDescription: (
      <>
        The project is supported by substantial long-term, firm volume
        commitments. WhiteWater is in the process of expanding Agua Blanca’s
        connectivity and is considering a number of options to expand overall
        pipeline capacity.
        <br />
        <br />
        Inquiries regarding Agua Blanca should be directed to ab@wwm-llc.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT WHITEWATER MIDSTREAM
        </Typography>
        WhiteWater Midstream is an Austin based, independent infrastructure
        company that provides transportation services to domestic oil and gas
        plays.WhiteWater was founded in 2016 and is backed by equity commitments
        from Denham Capital Management and Ridgemont Equity Partners.For more
        information about WhiteWater, visit www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT DENHAM CAPITAL
        </Typography>
        Denham Capital is a leading energy-focused global private equity firm
        with more than $8.4 billion of invested and committed capital across
        eight fund vehicles. The firm makes direct investments across all stages
        of the corporate lifecycle. Denham’s investment professionals apply deep
        operational and industry experience and work in partnership with
        management teams to achieve long-term investment objectives. For more
        information about Denham Capital, visit www.denhamcapital.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT RIDGEMONT EQUITY PARTNERS
        </Typography>
        Ridgemont Equity Partners is a Charlotte-based middle market buyout and
        growth equity investor. Since 1993, the principals of Ridgemont have
        invested over $3.5 billion in 136 companies. The firm focuses on
        investments of $25 million to $100 million in industries in which it has
        deep expertise, including basic industries and services, energy,
        healthcare and telecommunications/media/technology. For more information
        about Ridgemont, visit www.ridgemontep.com.
        <br />
        <br />
      </>
    ),
  },
  {
    title:
      "WhiteWater Midstream Announces New Commitments and Strategic Partner",
    description:
      "WhiteWater Midstream today announced that it has recently completed an agreement with WPX Energy, which provides WPX with 300,000 MMBtu/d of natural gas transportation capacity from the Stateline area to Waha and grants WPX a 10 percent ownership interest in Agua Blanca. As part of the agreement, WPX has the right to increase its capacity to 500,000 MMBtu/d and its ownership up to 20 percent.",
    date: "June 13, 2017",
    fullDescription: (
      <>
        Construction of Agua Blanca remains on schedule with the initial
        commissioning of the project expected during the fourth quarter of 2017
        and the complete path from Orla to Waha operational by the end of the
        first quarter of 2018.
        <br />
        <br />
        “We are excited to be partnering with a company the caliber of WPX on
        Agua Blanca. Their team has done an extraordinary job in the Delaware
        Basin positioning WPX for substantial growth over the coming years. WPX
        is subscribing for a significant amount of capacity on the pipe, and
        with potential future Permian growth and strong interest from additional
        shippers, we are already considering expansion options for Agua Blanca,”
        said WhiteWater Chief Executive Officer Christer Rundlof.
        <br />
        <br />
        Inquiries regarding Agua Blanca should be directed to ab@wwm-llc.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT AGUA BLANCA
        </Typography>
        Agua Blanca will initially consist of 75 miles of 36” diameter pipe with
        a capacity of 1.25 Bcf/d, expandable to 1.75 Bcf/d with minimal capex.
        The project is supported by substantial long-term, firm volume
        commitments. The initial path of Agua Blanca will be from Orla, Texas to
        the Waha Hub, servicing portions of Culberson, Loving, Pecos, Reeves and
        Ward counties. Agua Blanca will have multiple direct downstream
        connections including to the Trans-Pecos Header.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT WHITEWATER MIDSTREAM
        </Typography>
        WhiteWater Midstream is an Austin based, independent midstream company
        that provides gathering, treating, processing and transportation
        services to domestic oil and gas plays. WhiteWater was founded in 2016
        and is backed by equity commitments from Denham Capital Management and
        Ridgemont Equity Partners. For more information about WhiteWater, visit
        www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT DENHAM CAPITAL
        </Typography>
        Denham Capital is a leading energy-focused global private equity firm
        with more than $8.4 billion of invested and committed capital across
        eight fund vehicles. The firm makes direct investments across all stages
        of the corporate lifecycle. Denham’s investment professionals apply deep
        operational and industry experience and work in partnership with
        management teams to achieve long-term investment objectives. For more
        information about Denham Capital, visit www.denhamcapital.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT RIDGEMONT EQUITY PARTNERS
        </Typography>
        Ridgemont Equity Partners is a Charlotte-based middle market buyout and
        growth equity investor. Since 1993, the principals of Ridgemont have
        invested over $3.5 billion in 136 companies. The firm focuses on
        investments of $25 million to $100 million in industries in which it has
        deep expertise, including basic industries and services, energy,
        healthcare and telecommunications/media/technology. For more information
        about Ridgemont, visit www.ridgemontep.com.
        <br />
        <br />
      </>
    ),
  },
  {
    title: "WhiteWater Midstream Announces Delaware Basin Natural Gas Pipeline",
    description:
      "Austin, Texas – February 23, 2017 – WhiteWater Midstream today announced that it has begun construction of the Agua Blanca Pipeline, a Delaware Basin, intrastate natural gas pipeline. The initial path of Agua Blanca will be from Orla, Texas to the Waha Hub, servicing portions of Culberson, Loving, Pecos, Reeves and Ward counties. Agua Blanca will have multiple direct downstream connections including to the Trans-Pecos Header.",
    date: "February 23, 2017",
    fullDescription: (
      <>
        Agua Blanca will initially consist of 75 miles of 36” diameter pipe with
        a capacity of 1.25 Bcf/d, expandable to 1.75 Bcf/d. The project is
        supported by over 500 MMcf/d of long-term, firm volume commitments.
        WhiteWater expects to accept first volumes by the fourth quarter of
        2017.
        <br />
        <br />
        “Agua Blanca will provide producers and processors with a much needed
        outlet for the rapidly growing natural gas volumes in the Delaware
        Basin. Our customers will have a direct path to multiple delivery points
        including the attractive, growing Mexican power market,” said WhiteWater
        Chief Executive Officer Christer Rundlof.
        <br />
        <br />
        Inquiries regarding Agua Blanca should be directed to ab@wwm-llc.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT WHITEWATER MIDSTREAM
        </Typography>
        WhiteWater Midstream is an Austin based, independent midstream company
        that provides gathering, treating, processing and transportation
        services to domestic oil and gas plays. WhiteWater was founded in 2016
        and is backed by equity commitments from Denham Capital Management and
        Ridgemont Equity Partners. For more information about WhiteWater, visit
        www.whitewatermidstream.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT DENHAM CAPITAL
        </Typography>
        Denham Capital is a leading energy-focused global private equity firm
        with more than $8.4 billion of invested and committed capital across
        eight fund vehicles. The firm makes direct investments across all stages
        of the corporate lifecycle. Denham’s investment professionals apply deep
        operational and industry experience and work in partnership with
        management teams to achieve long-term investment objectives. For more
        information about Denham Capital, visit www.denhamcapital.com.
        <br />
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          ABOUT RIDGEMONT EQUITY PARTNERS
        </Typography>
        Ridgemont Equity Partners is a Charlotte-based middle market buyout and
        growth equity investor. Since 1993, the principals of Ridgemont have
        invested over $3.5 billion in 136 companies. The firm focuses on
        investments of $25 million to $100 million in industries in which it has
        deep expertise, including basic industries and services, energy,
        healthcare and telecommunications/media/technology. For more information
        about Ridgemont, visit www.ridgemontep.com.
        <br />
        <br />
      </>
    ),
  },
];

export default function News() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [expanded, setExpanded] = useState(null);

  const handleExpandClick = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100vw",
        backgroundColor: "white",
        color: "white",
        textAlign: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${Operations2MinNew})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "50px",
        }}
      ></Box>
      {/* Add fade-in animation to the title */}
      <Box sx={{ padding: "60px 4%", backgroundColor: "white" }}>
        <motion.div variants={fadeInVariant} initial="hidden" animate="visible">
          <Typography
            variant="h3"
            sx={{
              mb: 4,
              fontWeight: 400,
              color: "black",
              fontSize: isMobile ? "40px" : "50px",
              textAlign: "left",
            }}
          >
            Latest News
          </Typography>
        </motion.div>

        {/* Grid layout for news items */}
        <Grid container spacing={4} justifyContent="center">
          {newsData.map((news, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              {/* Add fade-in animation to each card */}
              <motion.div
                variants={fadeInVariant}
                initial="hidden"
                animate="visible"
              >
                <Card
                  variant="outlined"
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)",
                    borderRadius: "8px",
                    height: "auto",
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {news.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", marginBottom: "16px" }}
                    >
                      {news.description}
                    </Typography>

                    <Collapse
                      in={expanded === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Typography
                        variant="body2"
                        sx={{ marginTop: "8px", color: "text.secondary" }}
                      >
                        {news.fullDescription}
                      </Typography>
                    </Collapse>

                    {/* Divider below the text */}
                    <Divider sx={{ marginBottom: "16px" }} />

                    {/* Display the date */}
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "right", opacity: 0.7 }}
                    >
                      {news.date}
                    </Typography>

                    {/* Learn More Button */}
                    <Box sx={{ marginTop: "16px", textAlign: "right" }}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => handleExpandClick(index)}
                      >
                        {expanded === index ? "Show Less" : "Learn More"}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
