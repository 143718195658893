import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import EightOneOne from "../assets/optimized/811.jpg";
import API from "../assets/optimized/api-logo.png";
import GPAM from "../assets/optimized/gpam-logo.png";
import TPA from "../assets/optimized/tpa-loto.png";
import TXOGA from "../assets/optimized/txoga.png";
import ONE from "../assets/optimized/onefuturenew-logo.png";
import SGA from "../assets/optimized/sga-logo-tag.png";
import EP from "../assets/optimized/ep-logo.png";
import lga from "../assets/optimized/lga.png";

import SafetyBack from "../../static/assets/cow.webp";

const getVariants = (index) => ({
  hidden: {
    opacity: 0,
    transform: `translateX(${index % 2 === 0 ? "-150px" : "150px"})`,
    willChange: "transform, opacity",
  },
  visible: {
    opacity: 1,
    transform: "translateX(0)",
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
});

const Safety = () => {
  const isMobile = useMediaQuery("(max-width:1500px)");
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        color: "white",
        textAlign: "center",
        paddingBottom: "100px",
      }}
    >
      {/* Header Box with 2-Column Grid */}
      <Box
        sx={{
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${SafetyBack})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundPositionY: "75%",
          padding: "50px",
        }}
      ></Box>

      {/* Content Box with Scroll Sections */}
      <Box
        sx={{
          paddingBottom: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Header Box with 2-Column Grid */}
        <Box
          sx={{
            minHeight: "25vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid
            container
            spacing={4}
            sx={{
              maxWidth: "1600px",
              width: "100%",
              textAlign: "left",
              paddingTop: "25px",
            }}
          >
            {/* Left Column (Title & Description) */}
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography
                variant="h3"
                sx={{
                  color: "black",
                  mb: 2,
                  fontSize: isMobile ? "40px" : "50px",
                }}
              >
                Safety & Sustainability
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "black",
                  mb: 2,
                  fontSize: isMobile ? "30px" : "40px",
                }}
              >
                Our Commitment
              </Typography>
              <Typography variant="body1" sx={{ color: "black", opacity: 0.8 }}>
                WhiteWater is committed to the safe and reliable operation and
                maintenance of our infrastructure assets. We take our ownership
                responsibilities seriously and understand the risk associated
                with the transportation and processing of natural gas and
                natural gas liquids (NGL’s). Keeping our neighbors safe,
                minimizing our overall environmental impact, and optimizing the
                value of the commodities we control are our top priorities.
                <br />
                <br />
                To fulfill this obligation, our experienced team is dedicated to
                meeting industry best practices and complying with all
                applicable requirements set forth by various state-level
                regulatory agencies and the Pipeline and Hazardous Material
                Safety Administration (PHMSA).
                <br />
                <br />
                As a protective measure, the majority of the 2.4 million miles
                of energy-related pipelines within the United States are buried.
                While all buried pipeline easements should be clearly marked
                with yellow pipeline markers, these markers do not provide
                adequate detail to ensure safe excavation activity. Additional
                safety measures are necessary to identify the location of buried
                pipelines and prevent excavation-related damage.
                <br />
                <br />
                WhiteWater registers its pipeline assets with the Texas One-Call
                damage prevention system (811) and provides accurate information
                and timely support to 3rd party excavators to minimize the risk
                of pipeline damage. Regardless of the size of your next
                excavation project, utilize this free service by dialing 811 or
                visiting{" "}
                <a
                  href="https://www.texas811.org"
                  target="_blank"
                  style={{ color: "black", textDecoration: "underline" }}
                >
                  www.texas811.org
                </a>{" "}
                to ensure that all underground utilities have been located and
                appropriately marked.
                <br />
                <br />
                In the event of an emergency, dial 911 immediately, but for any
                other questions or concerns, please call our 24-hour phone
                number at{" "}
                <a
                  href="tel:+18662771250"
                  style={{ color: "black", textDecoration: "underline" }}
                >
                  (866) 277-1250
                </a>
                .
                <br />
                <br />
              </Typography>
            </Grid>

            {/* ✅ Right Column (Image & Buttons) */}
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src={EightOneOne}
                  alt="Safety Image"
                  style={{
                    maxWidth: "250px",
                    height: "auto",
                    objectFit: "contain",
                    borderRadius: "8px",
                    marginBottom: "20px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgba(255,255,255,0.2)",
                      color: "black",
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: "16px",
                      padding: "10px 20px",
                    }}
                    href="/pdf/Brochure2017.pdf"
                    target="_blank"
                  >
                    View Our Safety Brochure
                  </Button>
                  <Button
                    variant="contained"
                    href="/pdf/environmental-commitment.pdf"
                    target="_blank"
                    sx={{
                      backgroundColor: "rgba(255,255,255,0.2)",
                      color: "black",
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: "16px",
                      padding: "10px 20px",
                    }}
                  >
                    Environmental Commitment
                  </Button>
                  <Button
                    variant="contained"
                    href="/pdf/operational-exellence2.pdf"
                    target="_blank"
                    sx={{
                      backgroundColor: "rgba(255,255,255,0.2)",
                      color: "black",
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: "16px",
                      padding: "10px 20px",
                    }}
                  >
                    Operational Excellence
                  </Button>
                  <Button
                    variant="contained"
                    href="/pdf/strong-governance.pdf"
                    target="_blank"
                    sx={{
                      backgroundColor: "rgba(255,255,255,0.9)",
                      color: "black",
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: "16px",
                      padding: "10px 20px",
                    }}
                  >
                    Strong Governance
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Divider
          sx={{
            margin: "25px auto",
            width: "80%",
            borderBottomWidth: "2px",
          }}
        />

        <ScrollSection
          index={0}
          title="Our Environmental Commitment"
          text={`Environmental consciousness is at the forefront of all that we do. WhiteWater is committed to mitigating environmental risk at every stage of our business, promoting biodiversity, restoring habitats, and being good stewards of our environment. <br /> <br />
            At the beginning of every project, a detailed permitting process and thorough review is undertaken to ensure our proposed routes minimize risk to the environment – this includes avoiding environmentally sensitive or archeological sites whenever possible, minimizing the number of water crossings and minimizing risk to bodies of water, ecological considerations, and various wildlife protection plans with special accommodations for endangered plants and animals determined by independent environmental experts.<br /> <br />
            Energy infrastructure projects and operations are subject to extensive regulatory oversight at the local, state and federal levels. Whitewater maintains compliance with all regulatory agencies such as the Texas Rail Road Commission, Texas Parks and Wildlife, US Fish and Wildlife, the US Army Corps of Engineers and the Environmental Protection Agency (EPA). Once a project is complete, WhiteWater upholds the highest standards for restoring local habitats, biodiversity and maintaining rights-of-way for our in-service operations.`}
        />
        <ScrollSection
          index={1}
          title="Safety & Compliance"
          text={`WhiteWater seeks to be a force for good in the communities where we live and operate through both regular volunteer events, annual charitable giving, and local community engagement."  <br /> <br />
            Prior to the start of every project, a host of community engagement and outreach initiatives are coordinated to establish regular communication with affected landowners and relevant stakeholders throughout the construction process. WhiteWater works to maintain timely, transparent and detailed communication with relevant stakeholders, and it is our ultimate goal to conduct our business in a manner that values and respects the interests of those involved and impacted by our projects. Once in-service, we diligently maintain rights-of-way both for the safety of our operations and communities.
 <br /> <br />

            `}
        />
        <ScrollSection
          index={2}
          title="Workforce Development"
          text="As an equal opportunity employer, WhiteWater aspires to be a leader in hiring and retaining people who have a diversity of talents, ideas, and experience as we continue to grow. The company is deeply committed to creating a company culture that reflects our values supporting the diversity of thought, different backgrounds, and all sorts of lived life experiences to make for a robust and thriving workforce. We believe this encourages more meaningful engagement among our employees, fosters innovation and makes us a stronger company. The corporate culture perpetuated by our company’s founders is deeply rooted in mutual respect, fair treatment, and the common goal of attaining success for the benefit of our employees, investors, partners, and customers. It is our intention that every WhiteWater employee feels heard, respected, and is given a fair opportunity to succeed."
        />
        <ScrollSection
          index={3}
          title="Fostering Strong Corporate Governance"
          text="Fostering a culture of accountability and integrity at all levels of our organization is critical to conducting secure, reliable and ethical business. The ability to conduct safe and secure business is paramount. Therefore, WhiteWater continues to invest in technologies that provide additional layers of protection to our physical and digital assets while protecting our operations from outside threats. In addition to overseeing responsible governance across our operated assets, comprehensive financial controls and upholding high ethical standards, all Board members and company leaders receive regular updates on our sustainability initiatives which support our investor interests."
        />
        <ScrollSection
          index={4}
          title="Industry Associations"
          text="WhiteWater is proud to be a member of several industry organizations that promote coordination and collaboration amongst our peers on issues that range from operational best practices to environmental goals and climate initiatives. Through these partnerships we share knowledge of new technologies, evolving best work practices, national and state-level community initiatives, and we help to drive innovative solutions to build for a lower carbon energy future."
        />
      </Box>
    </Box>
  );
};

const ScrollSection = ({ index, title, text }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <motion.div
      ref={ref}
      variants={getVariants(index)}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      style={{
        minHeight: "25vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: index % 2 === 0 ? "flex-start" : "flex-end",
        textAlign: index % 2 === 0 ? "left" : "right",
        padding: "50px",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        margin: "35px auto",
        borderRadius: "10px",
        color: "black",
        width: "80%",
      }}
    >
      <Typography variant="h4" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        component="div"
        dangerouslySetInnerHTML={{ __html: text }}
      />

      {index === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            href="/pdf/Whistler_ESMMP_website_7_9_2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              backgroundColor: "#1e3764",
              color: "white",
              textTransform: "none",
              borderRadius: "8px",
              padding: "10px 20px",
            }}
          >
            Whistler ESMMP
          </Button>

          <Button
            variant="contained"
            href="/pdf/MatterhornESMMP07_09_2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              backgroundColor: "#1e3764",
              color: "white",
              textTransform: "none",
              borderRadius: "8px",
              padding: "10px 20px",
            }}
          >
            Matterhorn ESMMP
          </Button>
        </Box>
      )}

      {/* Add logos only for index 4 (Industry Associations) */}
      {index === 4 && (
        <Grid
          container
          spacing={4}
          sx={{
            marginTop: "40px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Logo 1 */}
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={API}
              alt="Logo 1"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          {/* Logo 2 */}
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={GPAM}
              alt="Logo 2"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          {/* Logo 3 */}
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={TPA}
              alt="Logo 3"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          {/* Logo 4 */}
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={TXOGA}
              alt="Logo 4"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          {/* Logo 5 */}
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={ONE}
              alt="Logo 5"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          {/* Logo 6 */}
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={SGA}
              alt="Logo 6"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          {/* Logo 7 */}
          <Grid item xs={6} sm={4} md={2}>
            <img
              src={EP}
              alt="Logo 7"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
           {/* Logo 8 */}
           <Grid item xs={6} sm={4} md={2}>
            <img
              src={lga}
              alt="Logo 7"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
        </Grid>
      )}
    </motion.div>
  );
};

export default Safety;
