import { useState, useCallback } from "react";
import axios from "axios";

const API_URL = import.meta.env.VITE_API_URL;

const useDebtholderRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const sendRequest = useCallback(async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const payload = {
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      PhoneNumber: formData.PhoneNumber,
      Email: formData.Email,
      Company: formData.Company,
      Title: formData.Title,
      BondHolder: formData.BondHolder,
      Details: formData.Details,
    };

    try {
      const response = await axios.post(API_URL, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status >= 200 && response.status < 300) {
        setSuccess(true);
      } else {
        setError("Failed to submit. Please try again.");
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data?.message || "Server error occurred.");
      } else if (err.request) {
        setError("No response from server. Check your internet connection.");
      } else {
        setError("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return { sendRequest, loading, error, success };
};

export default useDebtholderRequest;
