import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Divider,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { motion } from "framer-motion";

import PipeInside from "../assets/optimized/pipe-inside.png";
import useDebtholderRequest from "../hooks/useDebtholderRequest";

const Debtholder = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { sendRequest, loading, error, success } = useDebtholderRequest();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    title: "",
    bondHolder: "",
    institution: "",
    explanation: "",
    agreeTerms: false,
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!formData.firstName)
      validationErrors.firstName = "First name is required";
    if (!formData.lastName) validationErrors.lastName = "Last name is required";
    if (!formData.email) validationErrors.email = "Email is required";
    if (!formData.bondHolder)
      validationErrors.bondHolder = "This field is required";
    if (!formData.agreeTerms)
      validationErrors.agreeTerms = "You must agree to the terms";

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const payload = {
        FirstName: formData.firstName,
        LastName: formData.lastName,
        PhoneNumber: formData.phone,
        Email: formData.email,
        Company: formData.company,
        Title: formData.title,
        BondHolder: formData.bondHolder,
        Details:
          formData.bondHolder === "yes"
            ? formData.institution
            : formData.explanation,
      };

      await sendRequest(payload);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const fadeInVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1 },
    },
  };

  return (
    <motion.div variants={fadeInVariant} initial="hidden" animate="visible">
      <Box
        sx={{
          minHeight: "100vh",
          width: "100vw",
          backgroundImage: `url(${PipeInside})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "40px 20px",
          boxSizing: "border-box",
          overflowX: "hidden",
          paddingTop: "110px",
        }}
      >
        {/*Form Container */}
        <Paper
          elevation={3}
          sx={{
            maxWidth: "600px",
            width: "100%",
            padding: { xs: 3, sm: 5 },
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            backdropFilter: "blur(5px)",
            textAlign: "center",
          }}
        >
          {/* Form Title */}
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              color: "black",
              fontSize: isMobile ? "30px" : "40px",
              marginBottom: "20px",
            }}
          >
            Debtholder Login
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="First Name *"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Last Name *"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Email *"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />

            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend">
                Are you a holder of Whistler Pipeline, LLC bonds? *
              </FormLabel>
              <RadioGroup
                row
                name="bondHolder"
                value={formData.bondHolder}
                onChange={handleChange}
                sx={{ justifyContent: "center", mt: 1 }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="YES" />
                <FormControlLabel value="no" control={<Radio />} label="NO" />
              </RadioGroup>
              {errors.bondHolder && (
                <Typography color="error">{errors.bondHolder}</Typography>
              )}
            </FormControl>

            {formData.bondHolder === "yes" && (
              <TextField
                fullWidth
                label="Institution or Individual that holds these bonds"
                name="institution"
                value={formData.institution}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
            )}

            {formData.bondHolder === "no" && (
              <TextField
                fullWidth
                label="Provide a detailed explanation"
                name="explanation"
                multiline
                rows={4}
                value={formData.explanation}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
            )}

            <Typography variant="body2" sx={{ mb: 2 }}>
              By accessing Whistler Pipeline, LLC’s restricted financial
              information, you agree to maintain confidentiality.
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  name="agreeTerms"
                  checked={formData.agreeTerms}
                  onChange={handleChange}
                />
              }
              label="I agree to these terms."
            />
            {errors.agreeTerms && (
              <Typography color="error">{errors.agreeTerms}</Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Request Access"
              )}
            </Button>

            {success && (
              <Typography color="green" sx={{ mt: 2 }}>
                Request submitted successfully!
              </Typography>
            )}
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </form>

          <Divider sx={{ marginTop: "30px", marginBottom: "30px" }} />

          {/* Form Title */}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "black",
              fontSize: "21px",
              marginBottom: "20px",
            }}
          >
            Already have an account?
          </Typography>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() =>
              window.open("https://login.global.datasite.com/login/", "_blank")
            }
          >
            Log In
          </Button>
        </Paper>
      </Box>
    </motion.div>
  );
};

export default Debtholder;
