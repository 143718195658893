import React, { useEffect, useState } from "react";
import axios from "axios";
import BPTW1 from "../assets/optimized/bptw1.jpg";
import BPTW2 from "../assets/optimized/bptw2.jpg";
import Operations2MinNew from "../../static/assets/pipey.webp";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import { motion } from "framer-motion";

const Careers = () => {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(
          "https://boards-api.greenhouse.io/v1/boards/whitewatermidstream/departments",
        );

        const formattedDepartments = response.data.departments
          .map((dept) => ({
            name: dept.name,
            jobs: dept.jobs.map((job) => ({
              title: job.title,
              location: job.location?.name || "Location not specified",
              url: job.absolute_url,
              category:
                job.metadata?.find((m) => m.name === "Category")?.value ||
                "Uncategorized",
            })),
          }))
          .filter((dept) => dept.jobs.length > 0);

        setDepartments(formattedDepartments);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  const fadeInVariant = {
    hidden: { opacity: 0, y: 10 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.1, duration: 0.5 },
    }),
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100vw",
        backgroundColor: "white",
        color: "white",
        textAlign: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${Operations2MinNew})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "50px",
        }}
      ></Box>

      <Box
        sx={{
          padding: "60px 4vw",
          background: "#f8f9fa",
          minHeight: "100vh",
        }}
      >
        {/* Page Title */}
        <motion.div variants={fadeInVariant} initial="hidden" animate="visible">
          <Typography
            variant="h3"
            sx={{
              fontWeight: 400,
              color: "#333",
              whiteSpace: "nowrap",
              marginBottom: "10px",
              textAlign: "left",
            }}
          >
            Careers
          </Typography>

          {/* Logos Positioned Directly Below the Title */}
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              paddingTop: "20px",
              paddingBottom: "50px",
            }}
          >
            <img
              src={BPTW1}
              alt="Best Places to Work 2020"
              style={{ height: "120px" }}
            />
            <img
              src={BPTW2}
              alt="Best Places to Work 2023"
              style={{ height: "120px" }}
            />
          </Box>
        </motion.div>

        {/* Job Departments */}
        {departments.map((dept, deptIndex) => (
          <Box
            key={deptIndex}
            sx={{ width: "100%", marginBottom: 5, textAlign: "left" }}
          >
            {/* Department Title */}
            <motion.div
              variants={fadeInVariant}
              initial="hidden"
              animate="visible"
              custom={deptIndex}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  marginBottom: 2,
                  color: "#444",
                  paddingLeft: "16px",
                  borderLeft: "5px solid #007bff",
                }}
              >
                {dept.name}
              </Typography>
            </motion.div>

            {/* Job Grid */}
            <Grid container spacing={3} sx={{ justifyContent: "flex-start" }}>
              {dept.jobs.map((job, jobIndex) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={jobIndex}>
                  <motion.div
                    variants={fadeInVariant}
                    initial="hidden"
                    animate="visible"
                    custom={jobIndex}
                  >
                    <Card
                      variant="outlined"
                      sx={{
                        width: "100%",
                        transition: "all 0.3s ease",
                        borderRadius: "12px",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                    >
                      <CardContent sx={{ textAlign: "center" }}>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {job.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {job.location}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#007bff",
                            cursor: "pointer",
                            marginTop: "10px",
                          }}
                          component="a"
                          href={job.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apply Now
                        </Typography>
                      </CardContent>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Careers;
