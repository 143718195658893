import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  ListItemIcon,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SecurityIcon from "@mui/icons-material/Security";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import WorkIcon from "@mui/icons-material/Work";
import ContactsIcon from "@mui/icons-material/Contacts";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import OgLogo from "../assets/optimized/wwm-og-logo.png";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const isMobile = useMediaQuery("(max-width:1300px)");

  const navLinks = [
    { name: "About", path: "/", icon: <HomeIcon /> },
    { name: "Operations", path: "/operations", icon: <BusinessIcon /> },
    { name: "Investments", path: "/investments", icon: <MonetizationOnIcon /> },
    {
      name: "Safety & Sustainability",
      path: "/safety",
      icon: <SecurityIcon />,
    },
    { name: "News", path: "/news", icon: <NewspaperIcon /> },
    { name: "Careers", path: "/careers", icon: <WorkIcon /> },
    { name: "Contact", path: "/contact", icon: <ContactsIcon /> },
  ];

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: "white",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
        padding: "10px 20px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "center",
        transition: "background-color 0.3s ease",
      }}
    >
      <Toolbar
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        {/* Logo */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={OgLogo} alt="WhiteWater Logo" width="200" />
        </Box>

        {/* Desktop Navigation */}
        {!isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
            {navLinks.map((item) => (
              <Button
                key={item.path}
                component={Link}
                to={item.path}
                sx={{
                  color: "black",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "400",
                  position: "relative",
                  transition: "color 0.3s ease",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    width: "0%",
                    height: "2px",
                    bottom: "-5px",
                    left: "50%",
                    backgroundColor: "#0a2455",
                    transition: "width 0.3s ease-in-out",
                    transform: "translateX(-50%)",
                  },
                  "&:hover": {
                    color: "#0a2455",
                    "&::after": {
                      width: "100%",
                    },
                  },
                }}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        )}

        {/* Desktop Login Buttons */}
        {!isMobile && (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              component={Link}
              to="/debtholder"
              sx={{
                backgroundColor: "transparent", // Transparent background for a sleek look
                color: "black",
                textTransform: "none",
                borderRadius: "8px", // Slightly rounded corners
                fontSize: "16px",
                fontWeight: "500",
                padding: "8px 20px",
                border: "1.5px solid rgba(0, 0, 0, 0.7)", // Subtle black border
                transition: "all 0.2s ease-in-out", // Reduce transition duration
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.3)", // Lighter change for less contrast
                  border: "1px solid black", // Reduce border thickness
                  transform: "scale(1.02)", // Reduce scaling effect
                },
              }}
            >
              Debtholder Login
            </Button>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "transparent",
                color: "black",
                textTransform: "none",
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "500",
                padding: "8px 20px",
                border: "1.5px solid rgba(0, 0, 0, 0.7)", // Subtle black border
                transition: "all 0.2s ease-in-out", // Reduce transition duration
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.3)", // Lighter change for less contrast
                  border: "1px solid black", // Reduce border thickness
                  transform: "scale(1.02)", // Reduce scaling effect
                },
              }}
              onClick={() =>
                window.open(
                  "https://web-prd.myquorumcloud.com/WWMA1ESUITE/Home/Index/uic.(static).QVpDashboardPortal",
                  "_blank",
                )
              }
            >
              Shipper Login
            </Button>
          </Box>
        )}

        {/* Mobile Menu Button */}
        {isMobile && (
          <IconButton
            edge="end"
            color="inherit"
            onClick={toggleDrawer(true)}
            sx={{
              outline: "none",
              boxShadow: "none",
              "&:focus": {
                outline: "none",
                boxShadow: "none",
              },
              "&:active": {
                outline: "none",
                boxShadow: "none",
              },
            }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
        )}
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "250px",
            backgroundColor: "#1A1A1A",
            color: "white",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: 2 }}>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>

        <List>
          {navLinks.map((item) => (
            <ListItem
              key={item.path}
              component={Link}
              to={item.path}
              onClick={() => setDrawerOpen(false)}
              sx={{
                textDecoration: "none",
                color: "white",
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "10px 15px",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
              }}
            >
              <ListItemIcon sx={{ color: "white", minWidth: "40px" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
          {/* Login Buttons in Mobile Drawer */}
          <Box sx={{ marginTop: 2, textAlign: "center", padding: "15px" }}>
            <Button
              component={Link}
              to="/debtholder"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "rgba(255,255,255,0.2)",
                color: "#ffffff",
                textTransform: "none",
                borderRadius: "8px",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              Debtholder Login
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "rgba(255,255,255,0.2)",
                color: "#ffffff",
                textTransform: "none",
                borderRadius: "8px",
                fontSize: "16px",
              }}
              onClick={() =>
                window.open(
                  "https://web-prd.myquorumcloud.com/WWMA1ESUITE/Home/Index/uic.(static).QVpDashboardPortal",
                  "_blank",
                )
              }
            >
              Shipper Login
            </Button>
          </Box>
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
