import React from "react";
import { Box, Typography, Grid, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import CountUp from "react-countup";

// Import Material-UI Icons
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PipelineIcon from "@mui/icons-material/LinearScale";
import SavingsIcon from "@mui/icons-material/Savings";
import BusinessIcon from "@mui/icons-material/Business";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BuildIcon from "@mui/icons-material/Build";
import OGMin from "../../static/assets/og2-min.webp";
const About = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const fadeInVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1 },
    },
  };

  const stats = [
    {
      icon: <AttachMoneyIcon sx={{ fontSize: 40 }} />,
      label: 14.2,
      suffix: "bn",
      desc: "Greenfield projects reaching final investment decision",
    },
    {
      icon: <PipelineIcon sx={{ fontSize: 40 }} />,
      label: 3300,
      suffix: "",
      desc: "Miles of pipeline reached FID with an average diameter of 36",
    },
    {
      icon: <SavingsIcon sx={{ fontSize: 40 }} />,
      label: 10.4,
      suffix: "bn",
      desc: "Debt raised across 29 financings",
    },
    {
      icon: <BusinessIcon sx={{ fontSize: 40 }} />,
      label: 10,
      suffix: "",
      desc: "Greenfield assets developed",
    },
    {
      icon: <AccessTimeIcon sx={{ fontSize: 40 }} />,
      label: 15,
      suffix: "+mm",
      desc: "Execution man hours",
    },
    {
      icon: <BuildIcon sx={{ fontSize: 40 }} />,
      label: 2.3,
      suffix: "mm",
      desc: "Installed and operated horsepower",
    },
  ];

  return (
    <motion.div variants={fadeInVariant} initial="hidden" animate="visible">
      <Box
        sx={{
          minHeight: "100vh",
          overflow: "hidden",
          width: "100vw",
          backgroundImage: `url(${OGMin})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <Box
          sx={{
            textAlign: "left",
            color: "white",
            maxWidth: "900px",
            margin: "auto",
            padding: "25px",
          }}
        >
          {/* ✅ Apply fade-in animation to the title */}
          <motion.div
            variants={fadeInVariant}
            initial="hidden"
            animate="visible"
          >
            <Typography
              variant="h3"
              sx={{
                marginBottom: "20px",
                fontSize: isMobile ? "40px" : "50px",
                marginTop: "60px",
                paddingTop: "25px",
                fontWeight: "light",
                textAlign: "left",
                marginLeft: "10%",
                marginRight: "10%",
              }}
            >
              About WhiteWater
            </Typography>
          </motion.div>

          <motion.div
            variants={fadeInVariant}
            initial="hidden"
            animate="visible"
          >
            <Typography
              variant="h6"
              sx={{
                lineHeight: "1.6",
                marginBottom: { xs: "0", md: "30px" },
                opacity: 0.9,
                maxWidth: "750px",
                paddingBottom: { xs: "35px", md: "0" },
                fontSize: isMobile ? "18px" : "24px",
                fontWeight: "light",
                textAlign: "left",
                marginLeft: "10%",
                marginRight: "10%",
              }}
            >
              WhiteWater is an Austin-based infrastructure company founded in
              2016. WhiteWater is partnered with multiple private equity funds
              and direct investors.
            </Typography>
          </motion.div>

          {/* ✅ Stats Section with Fade-in Effect */}
          <Grid
            container
            spacing={isMobile ? 3 : 6}
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: "100%", margin: "auto", paddingBottom: "85px" }}
          >
            {stats.map((item, index) => (
              <Grid
                item
                xs={4}
                sm={4}
                key={index}
                sx={{
                  textAlign: "center",
                  marginBottom: isMobile ? "20px" : "0",
                  paddingLeft: "0 !important",
                }}
              >
                <motion.div
                  variants={fadeInVariant}
                  initial="hidden"
                  animate="visible"
                >
                  <Box sx={{ marginBottom: "10px" }}>{item.icon}</Box>
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: isMobile ? "20px" : "28px",
                      marginBottom: "5px",
                    }}
                  >
                    <CountUp
                      start={0}
                      end={item.label}
                      duration={3}
                      separator=","
                      decimals={item.label % 1 !== 0 ? 1 : 0}
                    />
                    {item.suffix}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: isMobile ? "12px" : "14px",
                      opacity: 0.8,
                      maxWidth: "200px",
                      margin: "auto",
                    }}
                  >
                    {item.desc}
                  </Typography>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </motion.div>
  );
};

export default About;
