import React from "react";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Agua from "../assets/optimized/agua.png";
import Waha from "../assets/optimized/waha.jpeg";
import Carlsbad from "../assets/optimized/carlsbad.jpeg";
import Whistler from "../assets/optimized/whistler.jpeg";
import ADCC from "../assets/optimized/adcc.jpeg";
import Matterhorn from "../assets/optimized/matterhorn.png";
import Operations2MinNew from "../../static/assets/pipeFinal.webp";
const getVariants = (index) => ({
  hidden: {
    opacity: 0,
    transform: `translateX(${index % 2 === 0 ? "-150px" : "150px"})`,
    willChange: "transform, opacity",
  },
  visible: {
    opacity: 1,
    transform: "translateX(0)",
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
});

const Operations = () => {
  const isMobile = useMediaQuery("(max-width:1500px)");
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100vw",
        backgroundColor: "white",
        color: "white",
        textAlign: "center",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${Operations2MinNew})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "50px",
        }}
      ></Box>

      {/* Content Box with Sections */}
      <Box
        sx={{
          paddingBottom: "100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ padding: "15px", paddingTop: "60px" }}>
          <Typography
            variant="h3"
            sx={{ color: "black", mb: 2, fontSize: isMobile ? "40px" : "50px" }}
          >
            Operations Overview
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: "black", maxWidth: "800px", opacity: 0.8 }}
          >
            WhiteWater's natural gas operations help ensure a secure and
            dependable energy supply. As we continue to innovate and expand
            strategically, we are committed to shaping the future of energy by
            offering reliable, safe, and environmentally responsible natural gas
            solutions to meet the evolving needs of today's energy markets.
          </Typography>

          <Divider
            sx={{
              margin: "25px auto",
              width: "80%",
              borderBottomWidth: "2px",
            }}
          />
        </Box>

        {/* Section 1 */}
        <ScrollSection
          index={0}
          title="Agua Blanca"
          text={
            <>
              Agua Blanca is a Joint Venture with WhiteWater, MPLX, and
              Enbridge. Delaware Basin intrastate natural gas pipeline servicing
              portions of Culberson, Loving, Pecos, Reeves, Ward and Winkler
              counties. The system consists of ~200+ miles of large diameter
              pipelines with a system capacity of ~3.5 bcf/d. Pipeline has
              multiple deliveries in Waha.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Quick Facts
              </Typography>
              <Typography variant="body1">
                200+ miles <br />
                3.5 Bcf/d total capacity <br />
                10 Bcf/d interconnect capacity <br />
              </Typography>
            </>
          }
          image={Agua}
        />
        {/* Section 2 */}
        <ScrollSection
          index={1}
          title="Waha Gas Storage"
          text={
            <>
              WhiteWater’s Waha Gas Storage facilities are underground salt
              caverns located in Pecos County, Texas, immediately adjacent to
              the Agua Blanca header system. The Waha Gas Storage facilities are
              connected to Agua Blanca’s Waha header system and natural gas may
              be physically received from or delivered to the interconnecting
              facilities on the header. With six existing caverns and five
              additional permitted caverns, the facilities can provide
              approximately 10 Bcf of storage capacity once fully developed.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Quick Facts
              </Typography>
              <Typography variant="body1">
                ~2.0 Bcf of storage capacity
                <br />
              </Typography>
            </>
          }
          image={Waha}
        />

        {/* Section 3 */}
        <ScrollSection
          index={2}
          title="Carlsbad Gateway"
          text={
            <>
              Carlsbad Gateway Pipeline is owned by a Joint Venture with
              WhiteWater, MPLX, and Enbridge. Carlsbad Gateway contains two
              separate laterals: (i) a 23-mile, 24-inch lateral that originates
              in Eddy County, NM, near multiple gas processing facilities, and
              extends south into Culberson County, TX, where it interconnects
              with Agua Blanca; and (ii) a 20-mile, 20-inch lateral that
              originates in Lea County, NM, at Targa's Red Hills processing
              facility and extends south into North Loving County, TX, where it
              interconnects with Agua Blanca. The interstate Carlsbad Gateway
              Pipeline has a system capacity of up to 750,000 MMcf/d.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Quick Facts
              </Typography>
              <Typography variant="body1">
                43 miles <br />
                750 MMcf/d total capacity <br />
                1 Bcf/d interconnect capacity <br />
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1e3764",
                  color: "white",
                  textTransform: "none",
                  borderRadius: "8px",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
                component="a"
                href="https://web-prd.myquorumcloud.com/WWMPA1IPWS/?tspno=701"
                target="_blank"
                rel="noopener noreferrer"
              >
                Informational Postings
              </Button>
            </>
          }
          image={Carlsbad}
        />

        {/* Section 4 */}
        <ScrollSection
          index={3}
          title="Whistler"
          text={
            <>
              Whistler is a Joint Venture with WhiteWater, MPLX, and Enbridge.
              Whistler Pipeline is an approximately 450-mile, 42-inch intrastate
              pipeline (Whistler Mainline) that transports natural gas from the
              Permian Basin to a Agua Dulce, TX, providing customers with direct
              access to South Texas markets and consumers. An approximate
              80-mile, 36-inch lateral provides connectivity for gas processors
              in the Midland Basin.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Quick Facts
              </Typography>
              <Typography variant="body1">
                500+ miles
                <br />
                2.5 Bcf/d total capacity
                <br />
                12 Bcf/d interconnect capacity
                <br />
              </Typography>
            </>
          }
          image={Whistler}
        />

        {/* Section 5 */}
        <ScrollSection
          index={4}
          title="ADCC"
          text={
            <>
              The ADCC Pipeline is a joint venture between the Whistler
              Pipeline, LLC and Cheniere Energy, Inc. (NYSE American: LNG). The
              ADCC pipeline is an approximately 43-mile, 42-inch intrastate
              pipeline designed to transport up to 1.7 billion cubic feet per
              day (Bcf/d), expandable to 2.5 Bcf/d of natural gas from the
              terminus of the Whistler Pipeline in Agua Dulce, Texas to
              Cheniere’s Corpus Christi Liquefaction Facility.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Quick Facts
              </Typography>
              <Typography variant="body1">
                40+ miles
                <br />
                1.7 Bcf/d total capacity
                <br />
                4 Bcf/d interconnect capacity
                <br />
              </Typography>
            </>
          }
          image={ADCC}
        />

        {/* Section 6 */}
        <ScrollSection
          index={5}
          title="Matterhorn Express"
          text={
            <>
              Matterhorn Express is a joint venture with WhiteWater, EnLink
              Midstream, Devon Energy, and MPLX. Matterhorn Express is an
              approximately 490-mile, 42-inch intrastate pipeline designed to
              transport up to 2.5 billion cubic feet per day (Bcf/d) of natural
              gas from the Permian Basin, to the Katy area near Houston, Texas.
              An approximate 80-mile lateral provides connectivity for gas
              processors in the Midland Basin.
              <br />
              <br />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Quick Facts
              </Typography>
              <Typography variant="body1">
                600+ miles
                <br />
                2.5 Bcf/d total capacity
                <br />
                9 Bcf/d interconnect capacity
                <br />
              </Typography>
            </>
          }
          image={Matterhorn}
        />
      </Box>
    </Box>
  );
};

const ScrollSection = ({ index, title, text, image }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const isMobile = useMediaQuery("(max-width:600px)");

  const pdfData = [
    { title: "Agua Blanca", pdfPath: "/pdf/agua-map.pdf" },
    { title: "Waha Gas Storage", pdfPath: "/pdf/waha-map.pdf" },
    { title: "Carlsbad Gateway", pdfPath: "/pdf/gateway-map.pdf" },
    { title: "Whistler Pipeline", pdfPath: "/pdf/whistler-map.pdf" },
    { title: "ADCC", pdfPath: "/pdf/adcc-map.pdf" },
    { title: "Matterhorn", pdfPath: "/pdf/matterhorn-map.pdf" },
  ];

  const pdfLink = pdfData[index]?.pdfPath;

  return (
    <motion.div
      ref={ref}
      variants={getVariants(index)}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      style={{
        willChange: "transform, opacity",
        minHeight: "30vh",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "left",
        padding: "50px",
        backgroundColor: "white",
        color: "black",
        margin: "0px auto",
        borderRadius: "10px",
        width: "80%",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        marginBottom: "35px",
        paddingBottom: "15px",
      }}
    >
      {/* Left Side: Text */}
      <Box
        sx={{
          width: isMobile ? "100%" : "50%",
          paddingRight: isMobile ? 0 : "20px",
        }}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: "700px" }}>
          {text}
        </Typography>
      </Box>

      {/* Right Side: Image & Button */}
      <Box sx={{ width: isMobile ? "100%" : "50%", textAlign: "center" }}>
        <img
          src={image}
          alt={title}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />

        {/* ✅ Button Wrapper for Right Alignment */}
        {pdfLink && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              href={`${window.location.origin}${pdfLink}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                backgroundColor: "#1e3764",
                color: "white",
                textTransform: "none",
                borderRadius: "8px",
                padding: "10px 20px",
                margin: "5px",
              }}
            >
              View Full Size
            </Button>
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

export default Operations;
