import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Investments from "./pages/Investments";
import News from "./pages/News";
import Operations from "./pages/Operations";
import Safety from "./pages/Safety";
import Debtholder from "./pages/Debtholder";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/operations" element={<Operations />} />
        <Route path="/investments" element={<Investments />} />
        <Route path="/safety" element={<Safety />} />
        <Route path="/news" element={<News />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/debtholder" element={<Debtholder />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
