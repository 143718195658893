import React from "react";
import { Box, Typography, Grid, useMediaQuery, Button } from "@mui/material";
import { motion } from "framer-motion"; // Import framer-motion for animation

import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessIcon from "@mui/icons-material/Business";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AtxMin from "../../static/assets/atx4-min.webp";

const Contact = () => {
  const isMobile = useMediaQuery("(max-width:1200px)");

  const stats = [
    {
      icon: <ApartmentIcon sx={{ fontSize: 40 }} />,
      label: "Austin Office",
      desc: "100 Congress Ave. Austin, TX 78701",
    },
    {
      icon: <BusinessIcon sx={{ fontSize: 40 }} />,
      label: "Houston Office",
      suffix: "",
      desc: "12140 Wickchester Ln. Houston, TX 77079",
    },
    {
      icon: <LocationCityIcon sx={{ fontSize: 40 }} />,
      label: "Denver Office",
      suffix: "",
      desc: "400 Inverness Parkway Englewood, CO 80112",
    },
  ];

  const fadeInVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1 },
    },
  };

  return (
    <motion.div variants={fadeInVariant} initial="hidden" animate="visible">
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${AtxMin})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <Box
          sx={{
            textAlign: "left",
            color: "white",
            maxWidth: "900px",
            margin: "auto",
            padding: isMobile ? "0px 15px" : "0px 40px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "light",
              marginBottom: "20px",
              marginTop: isMobile ? "80px" : "60px",
              paddingLeft: "30px",
            }}
          >
            Emergency Contacts
          </Typography>
          <Typography
            variant="h6"
            sx={{
              lineHeight: "1.6",
              marginBottom: { xs: "0", md: "80px" },
              opacity: 0.9,
              maxWidth: "750px",
              paddingBottom: { xs: "45px", md: "0" },
              paddingLeft: "30px",
              fontWeight: "light",
            }}
          >
            In case of an emergency, please contact 1-866-277-1250
          </Typography>

          <Typography
            variant="h5"
            sx={{
              fontWeight: "light",
              marginBottom: "20px",
              paddingLeft: "30px",
            }}
          >
            Encroachments
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgba(255,255,255,0.2)",
              color: "#ffffff",
              textTransform: "none",
              borderRadius: "8px",
              fontSize: "16px",
              padding: "10px 20px",
              marginBottom: "0px",
              marginLeft: "23px",
            }}
            onClick={() =>
              window.open(
                "https://requests.onupkeep.com/#/srAFmo7kyo/work-orders/create",
                "_blank",
              )
            }
          >
            Encroachments Form
          </Button>

          {/* Stats Section */}
          <Grid
            container
            spacing={isMobile ? 3 : 6}
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: "100%", margin: "auto" }}
          >
            {stats.map((item, index) => (
              <Grid
                item
                xs={4}
                sm={4}
                key={index}
                sx={{
                  textAlign: "left",
                  marginBottom: isMobile ? "20px" : "0",
                }}
              >
                <Box sx={{ marginBottom: "10px" }}>{item.icon}</Box>

                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "light",
                    fontSize: isMobile ? "20px" : "28px",
                    marginBottom: "5px",
                  }}
                >
                  {item.label}
                  {item.suffix}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isMobile ? "12px" : "14px",
                    opacity: 0.8,
                    maxWidth: "200px",
                    margin: "auto",
                  }}
                >
                  {item.desc}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </motion.div>
  );
};

export default Contact;
