import { createTheme } from "@mui/material/styles";
import "@fontsource/eb-garamond/400.css"; // Regular
import "@fontsource/eb-garamond/700.css"; // Bold

const theme = createTheme({
  typography: {
    fontFamily: '"EB Garamond", serif',
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
});

export default theme;
