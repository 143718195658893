import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        textAlign: "center",
        padding: "10px 0",
        backgroundColor: "white",
        color: "black",
        fontSize: "14px",
        width: "100%",
        marginTop: "auto",
      }}
    >
      <Typography variant="body2">
        ©2025 WhiteWater Development, LLC. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
